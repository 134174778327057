import { ReactComponent as CalendarIcon } from '@assets/icons/fi-rr-calendar-2.svg';
import { ReactComponent as IconCheckCircle } from '@assets/icons/fi-rr-check-circle.svg';
import { ReactComponent as ClockIcon } from '@assets/icons/fi-rr-clock.svg';
import { ReactComponent as MobileIcon } from '@assets/icons/fi-rr-mobile-notch.svg';
import { ReactComponent as UsersIcon } from '@assets/icons/fi-rr-users.svg';
import { ReactComponent as CameraIcon } from '@assets/icons/fi-rr-video-camera-alt.svg';
import { Box, Flex, Group, Image, Stack, Text } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { GrowingButton } from '@shared/components/buttons/GrowingButton';
import { KeyValueInfoCard } from '@shared/components/KeyValueInfoCard';
import { ListInfoCard } from '@shared/components/ListInfoCard';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { PublicBookableScreeningAppointment, Role } from '@shared/gql/sdk';
import { colors } from '@shared/theme';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { env } from '../../env';
import { t } from '../../i18n';
import IFunnelContext from '../FunnelContext';

dayjs.extend(localizedFormat);
const getRoleLabel = (role: Role) => {
  switch (role) {
    case Role.Doctor:
      return t('doctor');
    case Role.Nurse:
      return t('nurse');
    case Role.Coach:
      return t('coach');
  }
};

export const ConfirmBooking: FunnelPageComponent<
  { selectedTimeslot?: PublicBookableScreeningAppointment },
  IFunnelContext
> = ({ funnelApi, context: { selectedTimeslot, userSession } }) => {
  const { t } = useTranslation();

  if (!selectedTimeslot) {
    return null;
  }

  const roleLabel = getRoleLabel(selectedTimeslot.userRole);
  const date = dayjs(selectedTimeslot?.startTime).format('LL');
  const startTime = dayjs(selectedTimeslot?.startTime).format('HH:mm');
  const endTime = dayjs(selectedTimeslot?.endTime).format('HH:mm');
  const imageSrc = selectedTimeslot.profilePictureImageId
    ? `${env.employeeImageAssets}/${selectedTimeslot.profilePictureImageId}`
    : undefined;

  return (
    <Stack>
      <Group justify="center">
        <StepTitle size="sm" title={t('bookYourCall')} style={{ maxWidth: 320 }} />
      </Group>

      <Stack gap="sm" align="center">
        <Stack align="center" gap={'xs'}>
          <Box w={64} h={64}>
            {imageSrc ? <Image src={imageSrc} w={64} h={64} radius={32} /> : null}
          </Box>

          <Text fw={600} size="lg" c={'embla.4'}>
            {selectedTimeslot.name}
          </Text>
          <Text size="md" c={'embla.4'}>
            {roleLabel}
          </Text>
        </Stack>
        <KeyValueInfoCard
          config={[
            {
              icon: <CalendarIcon color={colors.embla[2]} height={18} />,
              label: t('date'),
              value: date,
            },
            {
              icon: <ClockIcon color={colors.embla[2]} height={18} />,
              label: t('time'),
              value: `${startTime} - ${endTime}`,
            },
            {
              icon: <MobileIcon color={colors.embla[2]} height={18} />,
              label: t('how'),
              value: t('videoConsultation'),
            },
          ]}
        />

        <Stack gap="sm" align="center" mt={'md'}>
          <Text fw={600} size="sm" c={'embla.4'}>
            {t('firstNameWeLookForwardTo', { firstName: userSession?.firstName })}
          </Text>
          <ListInfoCard
            config={[
              {
                icon: iconWrapper(<CameraIcon color={colors.embla[3]} height={18} />),
                value: t('getAllYourQuestionsAnswered'),
              },
              {
                icon: iconWrapper(<UsersIcon color={colors.embla[3]} height={18} />),
                value: t('learnMoreAboutOurTeam'),
              },
            ]}
          />
        </Stack>
      </Stack>
      <Stack my={'md'}>
        <GrowingButton
          data-testid="next"
          leftSection={
            <Box mr={'sm'}>
              <IconCheckCircle color={colors.neutral[0]} height={20} />
            </Box>
          }
          onClick={() => funnelApi.next({})}
          buttonLabel={t('confirm')}
        />

        <BackButton onClick={() => funnelApi.back()} />
      </Stack>
    </Stack>
  );
};

const iconWrapper = (icon: ReactNode) => {
  return (
    <Flex align="center" justify="center" style={{ height: 24 }}>
      {icon}
    </Flex>
  );
};
