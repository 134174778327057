import api from '@shared/api';
import { FunnelPageData, funnelConfiguration, funnelPage } from '@shared/funnel-engine';
import { MedicationWantedAnswers, SexAssignedAtBirth, UserSignInFlowDecision } from '@shared/gql/sdk';
import { calculateBmi, resolveIntl } from '@shared/helpers';
import { env } from 'src/env';
import { resolveRecommendationLoader } from 'src/v3/loaders/recommendationLoader';
import { CheckoutOrBook } from 'src/v3/pages/Checkout/CheckoutOrBook';
import { Pay } from 'src/v3/pages/Checkout/Pay';
import { Recommendation } from 'src/v3/pages/Checkout/Recommendation';
import { CheckoutConfirmation } from 'src/v3/pages/CheckoutConfirmation';
import { ComorbidityQuestionsForCheckout } from 'src/v3/pages/ComorbidityQuestionsForCheckout';
import { Confirmation } from 'src/v3/pages/Confirmation';
import { ConfirmBooking } from 'src/v3/pages/ConfirmBooking';
import { ConfirmEmbeddedWithMembershipSummary } from 'src/v3/pages/ConfirmEmbeddedWithMembershipSummary';
import { ConfirmPhoneNumberWithMembershipSummary } from 'src/v3/pages/ConfirmPhoneNumberWithMembershipSummary';
import { CurrentMedications } from 'src/v3/pages/CurrentMedications';
import { EligibilityQuestionsForCheckout } from 'src/v3/pages/EligibilityQuestionsForCheckout';
import { MedicationWanted } from 'src/v3/pages/MedicationWanted';
import { PickTimeslot, PickTimeslotErrors } from 'src/v3/pages/PickTimeslot';
import { Pregnancy } from 'src/v3/pages/Pregnancy';
import { BiggestMotivator } from 'src/v3/pages/Program/BiggestMotivator';
import { FamiliarWithGlp } from 'src/v3/pages/Program/FamiliarWithGlp';
import { HeardAbout } from 'src/v3/pages/Program/HeardAbout';
import {
  LastingSolution,
  LongRoadData,
  MedicationAloneMyth,
  OurApproachEmbraces,
  RegainWeight,
  SimpleDoesntCutIt,
  SocialProof,
  StatsBrits,
  TakesTime,
  TheBiggestLie,
  WeOfferTwoPlans,
  WeightlossComplicated,
  WeightlossHard,
} from 'src/v3/pages/Program/InfoPages';
import { WeightlossApproach } from 'src/v3/pages/Program/WeightlossApproach';
import { WeightlossStatement } from 'src/v3/pages/Program/WeightlossStatement';
import { SexAssigned } from 'src/v3/pages/SexAssigned';

import { saveData } from './handlers/data';
import { registrationExitHandler } from './handlers/registration';
import { onPageEntry, onPageExit } from './handlers/tracking';
import IFunnelContext, { NotEligibleReason, ProductSelection } from '../../FunnelContext';
import { ConfirmPhoneNumber, ConfirmPhoneNumberErrors } from '../../pages/ConfirmPhoneNumber';
import { CurrentGLP1Medication } from '../../pages/CurrentGLP1Medication';
import { HeightWeight } from '../../pages/HeightWeight';
import { NotEligible } from '../../pages/NotEligible';
import { RegistrationErrors, RegistrationWithoutCoupon } from '../../pages/RegistrationWithoutCoupon';
import { anyTicked, getPriceInfoByProductSelection } from '../../util/helpers';
import { performPasswordLessLogin, startPasswordlessLogin } from '../../util/passwordLessLogin';

export const createV3FunnelConfig = (initialContext: Partial<IFunnelContext>) =>
  funnelConfiguration({
    replay: !!initialContext?.userSession,
    handlers: {
      onPageEntry: async ({ page, isReplay, context }) => {
        if (isReplay) return;

        onPageEntry(page, context);
      },
      onPageExit: ({ page, context, data, isReplay }) => {
        if (isReplay) return;

        onPageExit(page, context, data);
        saveData(data, context);
      },
      onComplete: async ({ data: { eligibility }, context: { notEligibleReason }, isReplay }) => {
        if (isReplay) return;

        await api.SignupFunnelFinalizeV3({
          data: {
            emailMarketing: eligibility.registration.emailMarketing,
            smsMarketing: eligibility.registration.emailMarketing,
            glp1: eligibility.glp1.value,
            comorbidity: eligibility.comorbidity,
            eligibility: eligibility.eligibility,
            height_weight: eligibility.height_weight,
            signupCurrentMedication: eligibility.current_medications,
            sexAssignedAtBirth: eligibility.sex_assigned.value,
            signupPregnantOrBreastfeeding: eligibility.pregnancy.value,
            signupMedicationWanted: eligibility.medication_wanted.value,
            heardAboutEmbla: eligibility.heard_about.value,
            notEligibleReason,
          },
          terminal: true,
        });
      },
    },
    config: {
      program: {
        weightloss_hard: funnelPage(WeightlossHard),
        biggest_motivator: funnelPage(BiggestMotivator),
        weightloss_complicated: funnelPage(WeightlossComplicated),
        stats_brits: funnelPage(StatsBrits),
        familiar_glp1: funnelPage(FamiliarWithGlp),
        weightloss_statement: funnelPage(WeightlossStatement),
        biggest_lie: funnelPage(TheBiggestLie),
        medication_alone_myth: funnelPage(MedicationAloneMyth),
        regain_weight: funnelPage(RegainWeight),
        weightloss_approach: funnelPage(WeightlossApproach),
        long_road: funnelPage(LongRoadData),
        simple_doesnt_cut_it: funnelPage(SimpleDoesntCutIt),
        lasting_solution: funnelPage(LastingSolution),
        our_approach: funnelPage(OurApproachEmbraces),
        takes_time: funnelPage(TakesTime),
        two_plans: funnelPage(WeOfferTwoPlans, {
          onEntry: async ({ context: { priceInfos: initialPriceInfos, prefilledDiscountCode }, funnelApi }) => {
            const { locale } = resolveIntl();

            const priceInfos = initialPriceInfos
              ? initialPriceInfos
              : (
                  await api.FinancialPrices({
                    locale,
                    promoCode: prefilledDiscountCode,
                  })
                ).FinancialPrices;

            funnelApi.updateContext({
              priceInfos,
            });
          },
        }),
        social_proof: funnelPage(SocialProof),
      },
      eligibility: {
        registration: funnelPage(RegistrationWithoutCoupon, {
          onExit: registrationExitHandler,
        }),
        confirm_phone: funnelPage(ConfirmPhoneNumber, {
          onEntry: async ({
            context: {
              userSession: { phoneNumber },
            },
          }) => {
            await startPasswordlessLogin(phoneNumber);
          },
          onExit: async ({ funnelApi, context }) => {
            const { User } = await api.UserGet();
            const activeScreening = User?.activeScreening;
            if (activeScreening) {
              funnelApi.updateContext({
                appointmentId: activeScreening.id,
                flowVariant: 'booking',
                userSession: { ...context.userSession, email: User.email },
              });
            }
          },
          actions: {
            confirmPhoneNumber: async (phoneNumber: string, code: string, { funnelApi }) => {
              const result = await performPasswordLessLogin(phoneNumber, code);
              if (!result) {
                return false;
              }

              funnelApi.updateContext({
                userSession: {
                  phoneNumber,
                  token: result.token,
                  userId: result.userId,
                  phoneConfirmed: true,
                },
              });
              sessionStorage.setItem('token', result.token);

              return true;
            },
            sendSMSCode: async (phoneNumber) => {
              await startPasswordlessLogin(phoneNumber);
            },
          },
        }),
        heard_about: funnelPage(HeardAbout),
        height_weight: funnelPage(HeightWeight, {
          onExit: async ({ data: { height, weight, unitSystem }, funnelApi }) => {
            const bmi = calculateBmi(weight, height);
            const getLowBmiReason = (): NotEligibleReason => {
              if (bmi < 18.5) return 'very-low-bmi';
              if (bmi < 27) return 'low-bmi';
            };

            funnelApi.updateContext({
              measurements: {
                bmi,
                height,
                weight,
                preferredUnitSystem: unitSystem,
              },
              notEligibleReason: getLowBmiReason(),
            });
          },
        }),
        eligibility: funnelPage(EligibilityQuestionsForCheckout, {
          loader: ({ data }) => {
            const selectedProduct = eligibilityQuestionsEvaluation(data)?.selectedProduct;
            if (selectedProduct === ProductSelection.CoachingOnly) {
              return resolveRecommendationLoader(selectedProduct);
            }
          },
          onExit: async ({ data, funnelApi }) =>
            funnelApi.updateContext({
              ...eligibilityQuestionsEvaluation(data),
            }),
        }),
        comorbidity: funnelPage(ComorbidityQuestionsForCheckout, {
          loader: ({
            context: {
              measurements: { bmi },
            },
            data,
          }) => {
            const product = comorbidityQuestionsEvaluation(data, bmi);
            if (product === ProductSelection.CoachingOnly) {
              return resolveRecommendationLoader(product);
            }
          },
          onExit: async ({
            context: {
              measurements: { bmi },
            },
            data,
            funnelApi,
          }) => {
            const product = comorbidityQuestionsEvaluation(data, bmi);

            funnelApi.updateContext({
              eligibleFor: product,
              selectedProduct: product,
              notEligibleReason: undefined,
            });
          },
        }),
        sex_assigned: funnelPage(SexAssigned),
        pregnancy: funnelPage(Pregnancy, {
          loader: ({ data: { value } }) => {
            if (value) {
              return resolveRecommendationLoader(ProductSelection.CoachingOnly);
            }
          },
          onExit: async ({ data: { value }, funnelApi }) => {
            if (value) {
              funnelApi.updateContext({
                eligibleFor: ProductSelection.CoachingOnly,
                selectedProduct: ProductSelection.CoachingOnly,
                notEligibleReason: undefined,
              });
            } else {
              funnelApi.updateContext({
                eligibleFor: undefined,
                selectedProduct: undefined,
                notEligibleReason: undefined,
              });
            }
          },
        }),
        current_medications: funnelPage(CurrentMedications, {
          loader: ({ data }) => {
            if (!data.NONE) {
              return resolveRecommendationLoader(ProductSelection.CoachingOnly);
            }
          },
          onExit: async ({ data, funnelApi }) => {
            if (data.NONE) {
              funnelApi.updateContext({
                eligibleFor: ProductSelection.MedicationAndCoaching,
                selectedProduct: ProductSelection.MedicationAndCoaching,
                notEligibleReason: undefined,
              });
            } else {
              funnelApi.updateContext({
                eligibleFor: ProductSelection.CoachingOnly,
                selectedProduct: ProductSelection.CoachingOnly,
                notEligibleReason: undefined,
              });
            }
          },
        }),
        thank_you: funnelPage(NotEligible, { terminal: true }),
        glp1: funnelPage(CurrentGLP1Medication, {
          onExit: async ({ data, funnelApi }) => {
            funnelApi.updateContext({
              onGLP1Medication: data.value,
            });
          },
        }),
        medication_wanted: funnelPage(MedicationWanted, {
          loader: ({ data: { value } }) =>
            resolveRecommendationLoader(
              value === MedicationWantedAnswers.No
                ? ProductSelection.CoachingOnly
                : ProductSelection.MedicationAndCoaching,
            ),
          onExit: async ({ data, funnelApi }) => {
            if (data.value === MedicationWantedAnswers.No) {
              funnelApi.updateContext({
                eligibleFor: ProductSelection.CoachingOnly,
                selectedProduct: ProductSelection.CoachingOnly,
                notEligibleReason: undefined,
              });
            } else {
              funnelApi.updateContext({
                eligibleFor: ProductSelection.MedicationAndCoaching,
                selectedProduct: ProductSelection.MedicationAndCoaching,
                notEligibleReason: undefined,
              });
            }
          },
        }),
      },
      checkout: {
        recommendation: funnelPage(Recommendation, {
          onEntry: async ({
            context: { selectedProduct, priceInfos: initialPriceInfos, prefilledDiscountCode },
            funnelApi,
          }) => {
            const { locale } = resolveIntl();

            const priceInfos = initialPriceInfos
              ? initialPriceInfos
              : (
                  await api.FinancialPrices({
                    locale,
                    promoCode: prefilledDiscountCode,
                  })
                ).FinancialPrices;

            funnelApi.updateContext({
              priceInfos,
              selectedProductPriceInfo: getPriceInfoByProductSelection(selectedProduct, priceInfos),
            });
          },
          onExit: async ({ data, funnelApi, context }) => {
            funnelApi.updateContext({
              selectedProduct: data.selectedProduct,
              selectedProductPriceInfo: getPriceInfoByProductSelection(data.selectedProduct, context.priceInfos),
            });
          },
        }),
        checkout_or_book: funnelPage(CheckoutOrBook, {
          onExit: async ({ data: { didSelectBooking, selectedProduct }, funnelApi, context }) => {
            funnelApi.updateContext({
              selectedProduct: selectedProduct,
              selectedProductPriceInfo: getPriceInfoByProductSelection(selectedProduct, context.priceInfos),
              flowVariant: didSelectBooking ? 'booking' : 'checkout',
            });
          },
        }),
        pay: funnelPage(Pay, {
          onEntry: async ({ funnelApi, context }) => {
            const subscriptionSetupResponse = await api.SubscriptionSetup({
              priceId: context.selectedProductPriceInfo.id,
            });
            funnelApi.updateContext({
              stripeCredentials: subscriptionSetupResponse.UserSubscriptionSetup,
            });
          },
        }),
        pick_timeslot: funnelPage(PickTimeslot, {
          onEntry: async ({ funnelApi }) => {
            const results = await api.BookableScreeningAppointments();
            funnelApi.updateContext({
              bookableAppointments: results.BookableScreeningAppointments || [],
            });
          },
          onExit: async ({ data: { selectedTimeslot }, funnelApi }) => {
            funnelApi.updateContext({ selectedTimeslot });
          },
        }),
        confirm_booking: funnelPage(ConfirmBooking, {
          onExit: async ({ funnelApi, context: { selectedTimeslot, userSession, embedded } }) => {
            if (embedded || userSession.phoneConfirmed) {
              const result = await api.AppointmentCreate({
                reason: 'SCREENING',
                calendarId: selectedTimeslot.calendarId,
                startTime: selectedTimeslot.startTime,
                endTime: selectedTimeslot.endTime,
                userId: userSession.userId,
              });
              funnelApi.updateContext({
                appointmentId: result.AppointmentCreate.id,
              });
            }
          },
        }),
        confirm_booking_phone: funnelPage(ConfirmPhoneNumber, {
          onEntry: async ({
            context: {
              userSession: { phoneNumber },
              flowVariant,
            },
          }) => {
            if (flowVariant === 'phone-already-registered') {
              await startPasswordlessLogin(phoneNumber);
            } else {
              await api.UserStartIdConfirmationSMS({ phoneNumber });
            }
          },
          onExit: async ({ data, funnelApi, context: { selectedTimeslot, userSession, flowVariant } }) => {
            const { phoneChanged, userPhoneNumber } = data;
            let updatedFlowVariant = flowVariant;
            if (phoneChanged) {
              const decisionResult = await api.UserGetSignInFlow({
                phoneNumber: userPhoneNumber,
              });
              if (decisionResult.UserGetSignInFlow.decision === UserSignInFlowDecision.Continue) {
                updatedFlowVariant = 'phone-already-registered';
              }
            }

            funnelApi.updateContext({
              flowVariant: updatedFlowVariant,
              userSession: {
                ...userSession,
                phoneNumber: userPhoneNumber,
                phoneConfirmed: true,
              },
            });

            try {
              const result = await api.AppointmentCreate({
                reason: 'SCREENING',
                calendarId: selectedTimeslot.calendarId,
                startTime: selectedTimeslot.startTime,
                endTime: selectedTimeslot.endTime,
                userId: userSession.userId,
              });
              funnelApi.updateContext({
                appointmentId: result.AppointmentCreate.id,
              });
            } catch (err: unknown) {
              if (err?.toString().includes('CODE_3001_APPOINTMENT_DATE_NOT_AVB')) {
                return ConfirmPhoneNumberErrors.AppointmentNotAvailable;
              }
              throw err;
            }
          },
          actions: {
            confirmPhoneNumber: async (phoneNumber: string, code: string, { funnelApi, context: { flowVariant } }) => {
              if (flowVariant === 'phone-already-registered') {
                const result = await performPasswordLessLogin(phoneNumber, code);
                if (result) {
                  funnelApi.updateContext({
                    userSession: {
                      phoneNumber,
                      token: result.token,
                      userId: result.userId,
                      phoneConfirmed: true,
                    },
                  });
                  return true;
                } else {
                  return false;
                }
              } else {
                try {
                  const result = await api.UserCompleteIdConfirmationSMS({
                    phoneNumber,
                    code,
                  });
                  return result.UserCompleteIdConfirmation.success;
                } catch (error) {
                  return false;
                }
              }
            },
            sendSMSCode: async (phoneNumber, { context }) => {
              const { flowVariant } = context;
              if (flowVariant === 'phone-already-registered') {
                await startPasswordlessLogin(phoneNumber);
              } else {
                await api.UserStartIdConfirmationSMS({ phoneNumber });
              }
            },
          },
        }),
        confirmation_booking: funnelPage(Confirmation, { terminal: true }),
        confirm_membership_phone: funnelPage(ConfirmPhoneNumberWithMembershipSummary, {
          onEntry: async ({
            context: {
              userSession: { phoneNumber },
              flowVariant,
            },
          }) => {
            if (flowVariant === 'phone-already-registered') {
              await startPasswordlessLogin(phoneNumber);
            } else {
              await api.UserStartIdConfirmationSMS({ phoneNumber });
            }
          },
          onExit: async ({ data, funnelApi, context: { userSession, flowVariant } }) => {
            const { phoneChanged, userPhoneNumber } = data;
            let updatedFlowVariant = flowVariant;
            if (phoneChanged) {
              const decisionResult = await api.UserGetSignInFlow({
                phoneNumber: userPhoneNumber,
              });
              if (decisionResult.UserGetSignInFlow.decision === UserSignInFlowDecision.Continue) {
                updatedFlowVariant = 'phone-already-registered';
              }
            }

            funnelApi.updateContext({
              flowVariant: updatedFlowVariant,
              userSession: {
                ...userSession,
                phoneNumber: userPhoneNumber,
                phoneConfirmed: true,
              },
            });
          },
          actions: {
            confirmPhoneNumber: async (phoneNumber: string, code: string, { funnelApi, context: { flowVariant } }) => {
              if (flowVariant === 'phone-already-registered') {
                const result = await performPasswordLessLogin(phoneNumber, code);
                if (result) {
                  funnelApi.updateContext({
                    userSession: {
                      phoneNumber,
                      token: result.token,
                      userId: result.userId,
                      phoneConfirmed: true,
                    },
                  });
                  return true;
                } else {
                  return false;
                }
              } else {
                try {
                  const result = await api.UserCompleteIdConfirmationSMS({
                    phoneNumber,
                    code,
                  });
                  return result.UserCompleteIdConfirmation.success;
                } catch (error) {
                  return false;
                }
              }
            },
            sendSMSCode: async (phoneNumber, { context }) => {
              const { flowVariant } = context;
              if (flowVariant === 'phone-already-registered') {
                await startPasswordlessLogin(phoneNumber);
              } else {
                await api.UserStartIdConfirmationSMS({ phoneNumber });
              }
            },
          },
        }),
        confirmation_membership: funnelPage(CheckoutConfirmation, { terminal: true }),
        confirmation_membership_embedded: funnelPage(ConfirmEmbeddedWithMembershipSummary, { terminal: true }),
      },
    },
    routingRules: {
      eligibility: {
        registration: ({ context: { flowVariant }, error }) => {
          if (error === RegistrationErrors.PhoneOrEmailAlreadyInUse) {
            return 'eligibility/registration';
          }
          if (flowVariant === 'phone-already-registered') {
            return 'eligibility/confirm_phone';
          } else return 'eligibility/heard_about';
        },
        height_weight: ({ context: { notEligibleReason } }) => {
          if (['low-bmi', 'very-low-bmi'].includes(notEligibleReason)) {
            return 'eligibility/thank_you';
          }
        },
        eligibility: ({ context: { notEligibleReason, eligibleFor } }) => {
          if (['addiction', 'eating-disorder'].includes(notEligibleReason)) return 'eligibility/thank_you';

          if (eligibleFor === ProductSelection.CoachingOnly) return 'checkout/recommendation';
        },
        comorbidity: ({ context: { selectedProduct } }) =>
          selectedProduct === ProductSelection.CoachingOnly ? 'checkout/recommendation' : null,
        sex_assigned: ({ data }) =>
          data.value === SexAssignedAtBirth.Male ? 'eligibility/current_medications' : 'eligibility/pregnancy',
        pregnancy: ({ context: { selectedProduct } }) =>
          selectedProduct === ProductSelection.CoachingOnly ? 'checkout/recommendation' : null,
        current_medications: ({ context: { selectedProduct } }) =>
          selectedProduct === ProductSelection.CoachingOnly ? 'checkout/recommendation' : 'eligibility/glp1',
        confirm_phone: () => {
          return 'checkout/confirmation_booking';
        },
      },
      checkout: {
        recommendation: ({ data }) => {
          if (data.productChanged) {
            return 'checkout/recommendation';
          }
        },
        checkout_or_book: ({ data }) => {
          if (data.productChanged) {
            return 'checkout/checkout_or_book';
          }
          return data.didSelectBooking ? 'checkout/pick_timeslot' : 'checkout/pay';
        },
        pay: ({ context }) => {
          return context.embedded ? 'checkout/confirmation_membership_embedded' : 'checkout/confirm_membership_phone';
        },
        confirm_booking: ({ context: { userSession, embedded } }) => {
          if (userSession?.phoneConfirmed || embedded) return 'checkout/confirmation_booking';
          else return 'checkout/confirm_booking_phone';
        },
        confirm_booking_phone: ({ error }) => {
          if (error === ConfirmPhoneNumberErrors.AppointmentNotAvailable) {
            return {
              route: 'checkout/pick_timeslot',
              error: PickTimeslotErrors.AppointmentNotAvailable,
            };
          }
        },
      },
    },
    noMinimumLoadingTime: env.disableLoading,
    defaultLoader: {
      component: () => null,
      minimumDurationSeconds: 0.5,
      progressBar: true,
    },
    initialContext: {
      flowVariant: 'checkout',
      ...initialContext,
    },
  });

const eligibilityQuestionsEvaluation = (
  data: FunnelPageData<typeof EligibilityQuestionsForCheckout>,
): Pick<IFunnelContext, 'eligibleFor' | 'notEligibleReason' | 'selectedProduct'> => {
  if (data.alcohol_drugs || data.eatingdisorder) {
    return {
      eligibleFor: undefined,
      selectedProduct: ProductSelection.CoachingOnly,
      notEligibleReason: data.eatingdisorder ? 'eating-disorder' : 'addiction',
    };
  } else if (anyTicked(data)) {
    return {
      eligibleFor: ProductSelection.CoachingOnly,
      selectedProduct: ProductSelection.CoachingOnly,
      notEligibleReason: undefined,
    };
  } else {
    return {
      eligibleFor: undefined,
      selectedProduct: undefined,
      notEligibleReason: undefined,
    };
  }
};

const comorbidityQuestionsEvaluation = (
  data: FunnelPageData<typeof ComorbidityQuestionsForCheckout>,
  bmi: number,
): ProductSelection => {
  if (bmi >= 30) {
    return ProductSelection.CoachingAndMaybeMedication;
  }
  if (anyTicked(data)) {
    return ProductSelection.MedicationAndCoaching;
  } else {
    return ProductSelection.CoachingOnly;
  }
};
