import { useViewportSize } from "@mantine/hooks";
import { Alert, Badge, Button, Group, Paper, Stack, Text } from "@mantine/core";
import { FunctionComponent, MouseEventHandler } from "react";

import { ReactComponent as IconInfo } from "@assets/icons/fi-rr-info.svg";
import { Shades } from "../theme";

interface IProps {
  type: "info" | "warning" | "error";
  pillText: string;
  message: string;
  action?: { label: string; onClick: MouseEventHandler<HTMLButtonElement> };
}

export const Disclaimer: FunctionComponent<IProps> = ({
  type,
  pillText,
  message,
  action,
}) => {
  const { width } = useViewportSize();

  const getTypeColors = (): Record<string, Shades> => {
    switch (type) {
      case "error": {
        return {
          alertBg: undefined,
          alertColor: "error.0",
          badgeBg: undefined,
          badgeColor: "error.3",
          textColor: "error.4",
          actionBtnBg: "neutral.0",
          actionBtnColor: "error.2",
        };
      }
      case "warning": {
        return {
          alertBg: undefined,
          alertColor: "cream.0",
          badgeBg: undefined,
          badgeColor: "warning.3",
          textColor: "warning.4",
          actionBtnBg: "warning.0",
          actionBtnColor: "warning.5",
        };
      }
      case "info":
      default: {
        return {
          alertBg: "embla.0",
          alertColor: "cream.0",
          badgeBg: "embla.3",
          badgeColor: "neutral.0",
          textColor: "embla.4",
          actionBtnBg: "embla.0",
          actionBtnColor: "embla.5",
        };
      }
    }
  };
  const colors = getTypeColors();
  return (
    <Paper shadow="xs" style={{ width: "100%" }}>
      <Alert
        variant="filled"
        styles={{
          body: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          },
        }}
        bg={colors.alertBg}
        color={colors.alertColor}
        title={
          <Badge
            p="md"
            fw={600}
            fz={16}
            tt="none"
            radius={100}
            variant="filled"
            bg={colors.badgeBg}
            color={colors.badgeColor}
          >
            <Group align="center" gap="sm">
              <IconInfo width={16} />
              <Text>{pillText}</Text>
            </Group>
          </Badge>
        }
      >
        <Stack justify="center">
          <Text ta="center" color={colors.textColor}>
            {message}
          </Text>
          {action ? (
            <Group grow={width < 500} justify="center">
              <Button
                mb={"xs"}
                size={"md"}
                miw={222}
                bg={colors.actionBtnBg}
                color={colors.actionBtnColor}
                onClick={action.onClick}
                variant="outline"
              >
                {action.label}
              </Button>
            </Group>
          ) : null}
        </Stack>
      </Alert>
    </Paper>
  );
};
