import { SegmentedControl, SegmentedControlProps } from "@mantine/core";
import { FunctionComponent } from "react";
import classes from "./css-modules/EmblaSegmentedControl.module.css";

export const EmblaSegmentedControl: FunctionComponent<SegmentedControlProps> = (
  props
) => {
  return (
    <SegmentedControl
      {...props}
      radius="xl"
      classNames={{
        root: classes.root,
        indicator: classes.indicator,
        label: classes.label,
        control: classes.control,
      }}
      size="md"
    />
  );
};
