import StandardFooter from '@shared/components/StandardFooter';
import { TopBar } from '@shared/components/TopBar';
import { Funnel } from '@shared/funnel-engine';
import { debugEnabled } from '@shared/helpers';
import { useRef } from 'react';
import { t } from 'src/i18n';

import { createV3FunnelConfig } from './configs/uk-checkout-or-book/config';
import { IFunnelSession } from './FunnelContext';
import { hydrateDataFromSession } from './util/hydration';
import './FunnelUKWithCheckoutOrBook.css';

const mapSectionLabel = (label: string) => {
  switch (label) {
    case 'details':
      return t('topDetails');
    case 'program':
      return t('topProgram');
    case 'eligibility':
      return t('topEligibility');
    case 'checkout':
      return t('topCheckout');
  }
};

const getConfig = (session: IFunnelSession) => {
  const config = createV3FunnelConfig(session.context);
  config.initialData = hydrateDataFromSession(config.config, session);
  return config;
};

const FunnelUKWithCheckoutOrBookComponent: React.FunctionComponent<{
  session: IFunnelSession;
}> = ({ session }) => {
  const { context } = session || {};
  const funnelConfigV3 = useRef(getConfig(session)).current;

  return (
    <Funnel
      name="v3-uk-with-checkout-or-book"
      debug={debugEnabled()}
      options={funnelConfigV3}
      sectionDisplay={(sections) => (
        <TopBar
          sections={sections.map((s) => ({
            ...s,
            label: mapSectionLabel(s.label as string),
          }))}
        />
      )}
      footerDisplay={() => (!context?.embedded ? <StandardFooter /> : null)}
    />
  );
};

export default FunnelUKWithCheckoutOrBookComponent;
