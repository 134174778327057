import AppIllustration from "@assets/images/app_illustration.svg";
import { Flex, Image, Stack, Text } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { BackButton } from "@shared/components/buttons/BackButton";
import { NextButton } from "@shared/components/buttons/NextButton";
import { StepTitle } from "@shared/components/StepTitle";
import { FunnelPageComponent } from "@shared/funnel-engine";
import { useTranslation } from "react-i18next";

import IFunnelContext from "../FunnelContext";

export const ProbablyEligible: FunnelPageComponent<
  Record<string, never>,
  IFunnelContext
> = ({ funnelApi }) => {
  const { t } = useTranslation();
  const { width } = useViewportSize();

  return (
    <Stack>
      <StepTitle
        c={"embla.3"}
        title={t("itLooksLikeWeCanOfferYouAProgramAtEmbla")}
      />
      <Text ta="center" c="embla.4">
        {t("probablyEligible.nextStepIsToBookAScreening")}
      </Text>
      <Flex justify="center" pt={width > 375 ? 70 : null}>
        <Image width={250} src={AppIllustration} />
      </Flex>
      <Stack>
        <NextButton onClick={() => funnelApi.next({})} />
        <BackButton onClick={() => funnelApi.back()} />
      </Stack>
    </Stack>
  );
};
