import api, { isAuthenticated } from '@shared/api';
import { FunnelEngineData } from '@shared/funnel-engine';
import IFunnelContext from 'src/v3/FunnelContext';

import { createFunnelV3DKConfig } from '../config';

type Data = FunnelEngineData<ReturnType<typeof createFunnelV3DKConfig>['config']>;

export const saveData = (data: Data, context: IFunnelContext) => {
  if (!isAuthenticated()) return;

  try {
    const { eligibility } = data;
    api.SignupFunnelFinalizeV3({
      data: {
        glp1: eligibility.glp1.value,
        comorbidity: eligibility.comorbidity,
        eligibility: eligibility.eligibility,
        height_weight: eligibility.height_weight,
        emblaShareCode: eligibility.registration.coupon,
        notEligibleReason: context.notEligibleReason,
        heardAboutEmbla: eligibility.heard_about.value,
      },
      terminal: false,
    });
  } catch (e) {
    console.warn('Failed to save data');
  }
};
