import pens from '@assets/program/pens.png';
import { Flex, Image, Stack, Text } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { RadioCard } from '@shared/components/RadioCard';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import IFunnelContext from '../../FunnelContext';

export const FamiliarWithGlp: FunnelPageComponent<
  {
    familiarWithGlp?: 'yes' | 'no' | null;
  },
  IFunnelContext
> = ({ data: { familiarWithGlp: initialValue }, funnelApi }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(initialValue);

  return (
    <Stack>
      <Stack py={'xl'}>
        <Flex justify="center">
          <Image src={pens} w={320} />
        </Flex>
        <Text ta="center" size={'xl'} c="embla.4">
          <Trans i18nKey={'beforeWeContinue'} />
        </Text>
        <Text ta="center" size={'xl'} c="embla.4">
          <Trans i18nKey={'areYouFamiliarWithGlp1'} />
        </Text>
        <Stack gap="sm">
          <RadioCard
            key={'yes'}
            label={t('yes')}
            checked={value === 'yes'}
            select={() => {
              setValue('yes');
            }}
          />
          <RadioCard
            key={'no'}
            label={t('no')}
            checked={value === 'no'}
            select={() => {
              setValue('no');
            }}
          />
        </Stack>
      </Stack>

      <NextButton
        disabled={!value}
        onClick={() =>
          funnelApi.next({
            familiarWithGlp: value,
          })
        }
      />
      <BackButton onClick={() => funnelApi.back()} />
    </Stack>
  );
};
