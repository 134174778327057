import { Stack, Text } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { RadioCard } from '@shared/components/RadioCard';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import IFunnelContext from '../../FunnelContext';

type WeightlossStatementResult = 'medicationIsCompleteSolution' | 'thereIsNoEasyFix';

export const WeightlossStatement: FunnelPageComponent<
  {
    value?: WeightlossStatementResult;
  },
  IFunnelContext
> = ({ data: { value: initialValue }, funnelApi }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(initialValue);
  const { width } = useViewportSize();
  const isMobile = width < 500;

  return (
    <Stack style={isMobile ? { flex: 1, alignSelf: 'center' } : { height: 600 }}>
      <Stack align="center" justify="center" style={{ flex: 1 }}>
        <Text ta="center" size={'xl'} c="embla.4">
          <Trans i18nKey={'whichOfTheseStatements'} />
        </Text>
        <Stack gap="sm">
          <RadioCard
            key={'medicationIsCompleteSolution'}
            label={t('medicationIsACompleteSolution')}
            checked={value === 'medicationIsCompleteSolution'}
            select={() => {
              setValue('medicationIsCompleteSolution');
            }}
          />
          <RadioCard
            key={'thereIsNoEasyFix'}
            label={t('thereIsNoEasyFix')}
            checked={value === 'thereIsNoEasyFix'}
            select={() => {
              setValue('thereIsNoEasyFix');
            }}
          />
        </Stack>
      </Stack>
      <NextButton
        disabled={!value}
        onClick={() =>
          funnelApi.next({
            value,
          })
        }
      />
      <BackButton onClick={() => funnelApi.back()} />
    </Stack>
  );
};
