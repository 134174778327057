import { ReactComponent as IconInfo } from '@assets/icons/fi-rr-info.svg';
import { ReactComponent as IconCheckbox } from '@assets/icons/icon-checkbox.svg';
import { ReactComponent as IconCross } from '@assets/icons/icon-cross.svg';
import sixtySixPercent from '@assets/program/66percent.png';
import complexApproach from '@assets/program/complex-approach.png';
import longroad from '@assets/program/longroad.png';
import memberOda from '@assets/program/member-oda.png';
import pricecardCoaching from '@assets/program/pricecard-coaching.png';
import pricecardMedicinePlusCoaching from '@assets/program/pricecard-medicine-plus-coaching.png';
import weightlossFactors from '@assets/program/weightloss-factors.png';
import { Badge, Card, Flex, Group, Image, Stack, Text } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { TrustBox, TrustBoxMicroTrustScore } from '@shared/components/TrustBox';
import { FunnelPageComponent, IFunnelApi } from '@shared/funnel-engine';
import { FinancialPrice } from '@shared/gql/sdk';
import { formatAmount } from '@shared/helpers';
import { colors } from '@shared/theme';
import { FunctionComponent, PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import IFunnelContext from 'src/v3/FunnelContext';

const components = {
  highlight: <span style={{ fontWeight: 600 }} />,
};

const InfoContainer: FunctionComponent<
  PropsWithChildren & { funnelApi: IFunnelApi<Record<string, never>, IFunnelContext, undefined, undefined> }
> = ({ children, funnelApi }) => {
  const { width } = useViewportSize();
  const isMobile = width < 500;
  return (
    <Stack style={isMobile ? { flex: 1, maxWidth: 300, alignSelf: 'center' } : { height: 600 }}>
      <Stack align="center" justify="center" style={{ flex: 1 }}>
        {children}
      </Stack>
      <Stack>
        <Stack>
          <NextButton onClick={() => funnelApi.next({})} />
          {funnelApi.state?.hasPreviousPage && <BackButton onClick={() => funnelApi.back()} />}
        </Stack>
      </Stack>
    </Stack>
  );
};

export const WeightlossHard: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ funnelApi }) => (
  <InfoContainer funnelApi={funnelApi}>
    <Text ta="center" size={'xl'} c="embla.4">
      <Trans i18nKey={'oneThingWeKnowForSureIs'} components={components} />
    </Text>
    <Text ta="center" size={'xl'} c="embla.4" fw={600}>
      <Trans i18nKey={'weightlossIsHard'} components={components} />
    </Text>
    <Text ta="center" size={'xl'} c="embla.4">
      <Trans i18nKey={'andWhatMotivates'} components={components} />
    </Text>
  </InfoContainer>
);

export const WeightlossComplicated: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ funnelApi }) => (
  <InfoContainer funnelApi={funnelApi}>
    <Stack gap={'xl'} align="center">
      <Image src={weightlossFactors} w={271} />
      <Text ta="center" size={'xl'} c="embla.4">
        <Trans i18nKey={'weightLossFeelsComplicated'} components={components} />
      </Text>
      <Text ta="center" size={'xl'} c="embla.4">
        <Trans i18nKey={'andWeWontPretendOtherwise'} components={components} />
      </Text>
    </Stack>
  </InfoContainer>
);

export const StatsBrits: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ funnelApi }) => (
  <InfoContainer funnelApi={funnelApi}>
    <Stack style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Stack gap={'xl'}>
        <Text ta="center" size={'xl'} c="embla.4">
          <Trans i18nKey={'youAreNotTheOnlyOne'} components={components} />
        </Text>
        <Text ta="center" size={'xl'} c="embla.4">
          <Trans i18nKey={'52PercentOfBrits'} components={components} />
        </Text>
      </Stack>
    </Stack>
    <div style={{ textAlign: 'center' }}>
      <Text ta="center" size={'sm'} c="embla.4">
        *YouGov PLC. (2022, October 13). Available at:
        https://business.yougov.com/content/44057-52-worlds-adults-trying-lose-weight
      </Text>
    </div>
  </InfoContainer>
);

export const TheBiggestLie: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ funnelApi }) => (
  <InfoContainer funnelApi={funnelApi}>
    <Text ta="center" size={'xl'} c="embla.4">
      <Trans i18nKey={'theBiggestLieInWeightLoss'} components={components} />
    </Text>
  </InfoContainer>
);

export const MedicationAloneMyth: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ funnelApi }) => (
  <InfoContainer funnelApi={funnelApi}>
    <Stack gap={'xl'}>
      <Text ta="center" size={'xl'} c="embla.4">
        <Trans i18nKey={'viewingMedicationAlone'} components={components} />
      </Text>
      <Text ta="center" size={'xl'} c="embla.4">
        <Trans i18nKey={'usedAloneTheyOnlyGetYou'} components={components} />
      </Text>
    </Stack>
  </InfoContainer>
);

export const RegainWeight: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ funnelApi }) => (
  <InfoContainer funnelApi={funnelApi}>
    <Stack style={{ flex: 1 }} justify="center">
      <Stack>
        <Flex justify="center">
          <Image src={sixtySixPercent} w={170} />
        </Flex>
        <Text ta="center" size={'xl'} c="embla.4">
          <Trans i18nKey={'ofLostWeightReturns'} components={components} />
        </Text>
        <Text ta="center" size={'xl'} c="embla.4" my={'lg'}>
          <Trans i18nKey={'theStepTrialShowedPeople'} components={components} />
        </Text>
      </Stack>
    </Stack>
    <div style={{ textAlign: 'center' }}>
      <Text ta="center" size={'sm'} c="embla.4">
        *Diabetes Obes Metab.(2022, Aug). Available at: https://arc.net/l/quote/vhbklbxe
      </Text>
    </div>
  </InfoContainer>
);

export const LongRoadData: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ funnelApi }) => (
  <InfoContainer funnelApi={funnelApi}>
    <Stack justify="space-between" style={{ flex: 1 }}>
      <Stack gap={'xl'} align="center" mt={'xl'}>
        <Text ta="center" size={'xl'} c="embla.4">
          <Trans i18nKey={'ourDataShowsTheLongRoad'} components={components} />
        </Text>
        <Image src={longroad} w={343} />
      </Stack>
      <Text ta="center" size={'md'} c="embla.4" mb={'lg'} fw={600}>
        <Trans i18nKey={'dontJustTakeOurWordForIt'} components={components} />
      </Text>
    </Stack>
  </InfoContainer>
);

export const SimpleDoesntCutIt: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ funnelApi }) => (
  <InfoContainer funnelApi={funnelApi}>
    <Stack gap={'xl'}>
      <Text ta="center" size={'xl'} c="embla.4">
        <Trans i18nKey={'simpleJustDoesntCutIt'} components={components} />
      </Text>
      <Text ta="center" size={'xl'} c="embla.4">
        <Trans i18nKey={'forLongTermWeightLossWeNeedToConsider'} components={components} />
      </Text>
    </Stack>
  </InfoContainer>
);

export const LastingSolution: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ funnelApi }) => (
  <InfoContainer funnelApi={funnelApi}>
    <Stack gap={'xl'}>
      <Text ta="center" size={'xl'} c="embla.4">
        <Trans i18nKey={'thisIsWhatWeDoAtEmbla'} components={components} />
      </Text>
      <Text ta="center" size={'xl'} c="embla.4">
        <Trans i18nKey={'weDeliverARealLastingSolution'} components={components} />
      </Text>
      <TrustBox />
    </Stack>
  </InfoContainer>
);

export const OurApproachEmbraces: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ funnelApi }) => (
  <InfoContainer funnelApi={funnelApi}>
    <Stack gap={'sm'}>
      <Text ta="center" size={'xl'} c="embla.4">
        <Trans i18nKey={'ourApproachEmbracesTheComplexityOfYou'} components={components} />
      </Text>
      <Flex justify="center">
        <Image src={complexApproach} w={300} />
      </Flex>

      <Stack gap={0} align="center" pb={'md'}>
        <div
          style={{
            width: 0,
            height: 0,
            borderLeft: '20px solid transparent',
            borderRight: '20px solid transparent',
            borderBottom: '20px solid white',
          }}
        />
        <Card m={0} p={'sm'} radius={10}>
          <Text ta="center" size={'md'} c="embla.4">
            <Trans i18nKey={'withTailoredOneOnOneHealthCoaching'} components={components} />
          </Text>
        </Card>
      </Stack>
    </Stack>
  </InfoContainer>
);

export const TakesTime: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ funnelApi }) => (
  <InfoContainer funnelApi={funnelApi}>
    <Stack gap={'xl'}>
      <Text ta="center" size={'xl'} c="embla.4">
        <Trans i18nKey={'thisApproachTakesTime'} components={components} />
      </Text>
      <Text ta="center" size={'xl'} c="embla.4">
        <Trans i18nKey={'butItWillGetYouTheLongTerm'} components={components} />
      </Text>
    </Stack>
  </InfoContainer>
);

export const WeOfferTwoPlans: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({
  funnelApi,
  context: { priceInfos },
}) => {
  const { t } = useTranslation();

  const PriceCard = ({ price }: { price: FinancialPrice }) => {
    const InfoLine = ({ icon, text }: { icon: 'check' | 'cross'; text: string }) => (
      <Group gap={5}>
        {icon === 'check' ? <IconCheckbox width={16} height={16} /> : <IconCross width={16} height={16} />}
        <Text fw={'400'} size={'12px'} c="embla.4">
          {text}
        </Text>
      </Group>
    );

    const isCoaching = price.product.eligibilityType === 'COACHING';

    return (
      <Stack gap={0} p={0} w={150} align="center">
        <Card p={'sm'}>
          <Image src={isCoaching ? pricecardCoaching : pricecardMedicinePlusCoaching} height={100} width={150} />
          <Stack gap={'md'} align="center">
            <Stack h={45} justify="center">
              <Text ta={'center'} fw="600" w={90} c="embla.4">
                {isCoaching ? t('coaching') : t('medicationPlusCoaching')}
              </Text>
            </Stack>
            {isCoaching ? (
              <InfoLine icon={'cross'} text={t('noMedication')} />
            ) : (
              <InfoLine icon={'check'} text={t('medication')} />
            )}

            <InfoLine icon={'check'} text={t('personalCoach')} />
            <InfoLine icon={'check'} text={t('unlimitedSupport')} />
          </Stack>
        </Card>
        <Stack gap={0} pt={'sm'}>
          {price?.campaignAmount ? (
            <>
              <Text c="embla.4" td="line-through" ta={'center'} fw={600}>
                {formatAmount(price.amount, price.currency)}/{t('month')}
              </Text>
              <Text c="menthol.4" ta={'center'} fw={600}>
                {formatAmount(price.campaignAmount, price.currency)}/{price.campaignText && `${price.campaignText}`}
              </Text>
            </>
          ) : (
            <Text c="embla.4" ta={'center'} fw={400}>
              {formatAmount(price.amount, price.currency)}/{t('month')}
            </Text>
          )}
        </Stack>
      </Stack>
    );
  };

  return (
    <InfoContainer funnelApi={funnelApi}>
      <Badge
        p="md"
        fw={600}
        fz={16}
        tt="none"
        radius={100}
        variant="filled"
        bg={colors.menthol[3]}
        color={colors.neutral[0]}
      >
        <Group align="center" gap="sm">
          <IconInfo width={16} />
          <Text>
            <Trans i18nKey={'theEmblaApproach'} />
          </Text>
        </Group>
      </Badge>
      <Stack gap={'sm'}>
        <Text ta="center" size={'xl'} c="embla.4">
          <Trans i18nKey={'weOfferTwoPlans'} components={components} />
        </Text>
        <Group gap="sm" wrap="nowrap">
          <PriceCard price={priceInfos.find((pi) => pi.product.eligibilityType === 'COACHING_AND_MEDICATION')} />
          <PriceCard price={priceInfos.find((pi) => pi.product.eligibilityType === 'COACHING')} />
        </Group>
      </Stack>
    </InfoContainer>
  );
};

export const SocialProof: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ funnelApi }) => (
  <InfoContainer funnelApi={funnelApi}>
    <Stack gap={'xl'}>
      <Text ta="center" size={'xl'} c="embla.4">
        <Trans i18nKey={'weHaveHelpedThousandsOfRealPeopleLike'} components={components} />
      </Text>
      <Card p={0} bg={colors.embla[4]}>
        <Group wrap="nowrap" align="flex-start" gap={0} style={{ alignItems: 'stretch' }}>
          <Image src={memberOda} w={80} p={0} />
          <Stack gap={'md'} style={{ justifyContent: 'space-between' }} p={'md'} m={0}>
            <Text size={'xl'} c="neutral.0">
              <Trans i18nKey={'itsIncrediblyTailoredToMe'} components={components} />
            </Text>
            <Group>
              <Text size={'xl'} c="neutral.0" fw={'semibold'}>
                {'- member Oda'}
              </Text>
              <Badge tt="none" p="md" bg={colors.success[0]}>
                <Text size={'lg'} fw={'semibold'} c="success.4">
                  {'lost 10kg'}
                </Text>
              </Badge>
            </Group>
          </Stack>
        </Group>
        <Group wrap="nowrap" justify="center" bg={'embla.5'} py={'sm'}>
          <TrustBoxMicroTrustScore />
        </Group>
      </Card>
    </Stack>
  </InfoContainer>
);
