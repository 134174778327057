import imgAlina from '@assets/images/employees/alina.png';
import imgDebbie from '@assets/images/employees/debbie.png';
import imgEmma from '@assets/images/employees/emma.png';
import imgHenrik from '@assets/images/employees/henrik.png';
import imgMaria from '@assets/images/employees/maria.png';
import imgNicholas from '@assets/images/employees/nicholas.png';
import imgOliverV3 from '@assets/images/employees/oliver-v3.png';
import imgOliver from '@assets/images/employees/oliver.png';
import { CountryIso3166 } from '@shared/gql/sdk';
import { resolveIntl } from '@shared/helpers';
import { EmployeeData, EmployeeType } from '@shared/types';

import { t } from '../../i18n';

const Henrik = (): EmployeeData => ({
  imageSrc: imgHenrik,
  name: 'Henrik Gudbergsen',
  title: t('titleCmo'),
  quotes: [
    {
      quote: t('ourMembersLoseWeight'),
      time: 4500,
    },
  ],
});

export const Nicholas = (): EmployeeData => ({
  imageSrc: imgNicholas,
  name: 'Nicholas Syhler',
  title: t('titleDoctorCoFounder'),
  quotes: [
    { quote: t('qWeightLossIsNot'), time: 3000 },
    { quote: t('qWeHelped'), trustPilot: true, time: 4000 },
    { quote: t('qLetsFind'), time: 2000 },
  ],
});

export const Oliver = (): EmployeeData => ({
  imageSrc: imgOliver,
  name: 'Oliver Large',
  title: t('titleMedicalDirector'),
  quotes: [
    { quote: t('qWeightLossIsNot'), time: 3000 },
    { quote: t('qWeHelped'), trustPilot: true, time: 4000 },
    { quote: t('qLetsFind'), time: 2000 },
  ],
});

export const OliverV3 = (): EmployeeData => ({
  imageSrc: imgOliverV3,
  name: 'Oliver Large',
  title: t('titleGPMedicalDirector'),
  quotes: [
    { quote: t('qWeBelieveWeightLoss'), time: 3000 },
    { quote: t('qWeHaveHelpedThousands'), trustPilot: true, time: 4000 },
    { quote: t('qInOrderToHelp'), time: 2000 },
  ],
});

export const Emma = (): EmployeeData => ({
  imageSrc: imgEmma,
  name: 'Emma Brix',
  title: t('coach'),
  quotes: [{ quote: t('qGreatYouQualify'), time: 3000 }],
});

export const Maria = (): EmployeeData => ({
  imageSrc: imgMaria,
  name: 'Maria Olsen',
  title: t('titleHeadOfHealthCoaching'),
  quotes: [
    { quote: t('qBySmallSteps'), time: 3000 },
    { quote: t('qYouCanStillEat'), time: 3000 },
    { quote: t('qDontWorry'), time: 3000 },
  ],
});

export const Debbie = (): EmployeeData => ({
  imageSrc: imgDebbie,
  name: 'Debbie Degn',
  title: t('titleRegisteredNurse'),
  quotes: [{ quote: t('qGreatYouQualify'), time: 3000 }],
});

export const Alina = (indexOfSpecificQuoteToShow?: number): EmployeeData => {
  let quotes = [
    { quote: t('qWeightLossIsAPersonalJourney'), time: 4000 },
    { quote: t('qGreatYouQualify'), time: 4000 },
    { quote: t('qGreatYouQualifyForEmblaTeam'), time: 4000 },
    { quote: t('qGreatYouQualifyForEmblaCoach'), time: 4000 },
  ];

  if (indexOfSpecificQuoteToShow !== undefined) {
    quotes = [quotes[indexOfSpecificQuoteToShow]];
  }

  return {
    imageSrc: imgAlina,
    name: 'Alina Morozova',
    title: t('titleRegisteredNurse'),
    quotes,
  };
};

export const getEmployeeByType = (type: EmployeeType, isV3 = true) => {
  const { country } = resolveIntl();
  const OliverResolved = isV3 ? OliverV3 : Oliver;

  switch (type) {
    case 'NURSE':
      return country === CountryIso3166.Gb ? Alina() : Debbie();
    case 'NURSE_1':
      return Alina(0);
    case 'NURSE_2':
      return Alina(1);
    case 'NURSE_3':
      return Alina(2);
    case 'NURSE_4':
      return Alina(3);
    case 'MEDICAL':
      return country === CountryIso3166.Gb ? OliverResolved() : Nicholas();
    case 'CMO':
      return Henrik();
    case 'COACH':
      return Emma();
    case 'HEAD_OF_COACHING':
      return Maria();
  }
};

export const getLoadingTimeMsByType = (type: EmployeeType, isV3 = true) =>
  getEmployeeByType(type, isV3).quotes.reduce((prev, cur) => prev + cur.time, 0);
