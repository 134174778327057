import TrustPilotReviewSandra from '@assets/images/trustpilot-review-sandra.png';
import { Image, Loader, Stack } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { Trans } from 'react-i18next';
import IFunnelContext, { ProductSelection } from 'src/v3/FunnelContext';

import { Medication } from './Medication';
import { YourRecommendedMembership } from './YourRecommendedMembership';
import { SwitchProgram } from '../Program/SwitchProgram';

export const Recommendation: FunnelPageComponent<
  {
    selectedProduct: ProductSelection;
    productChanged?: boolean;
  },
  IFunnelContext
> = ({ context: { selectedProduct, selectedProductPriceInfo: price, eligibleFor }, funnelApi }) => {
  if (!price) return <Loader />;

  return (
    <Stack>
      <StepTitle title={<Trans i18nKey={'weBelieveThisPlanFitsYouBest'} />} />
      <Stack gap="lg">
        <YourRecommendedMembership price={price} selectedProduct={selectedProduct} selected={true} />
        {eligibleFor === ProductSelection.MedicationAndCoaching && (
          <SwitchProgram
            productChanged={true}
            selectedProduct={selectedProduct}
            onClick={() => {
              funnelApi.next({
                selectedProduct:
                  selectedProduct == ProductSelection.CoachingOnly
                    ? ProductSelection.MedicationAndCoaching
                    : ProductSelection.CoachingOnly,
                productChanged: true,
              });
            }}
          />
        )}

        <Image src={TrustPilotReviewSandra} />
        {selectedProduct === ProductSelection.MedicationAndCoaching && <Medication />}
        <Stack>
          <NextButton onClick={() => funnelApi.next({ selectedProduct })} />
          <BackButton onClick={() => funnelApi.back()} />
        </Stack>
      </Stack>
    </Stack>
  );
};
