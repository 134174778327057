import { ReactComponent as IconArrowUpRight } from "@assets/icons/fi-rr-arrow-up-right-from-square.svg";
import { ButtonProps } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { GrowingButton } from "./GrowingButton";

interface IProps {
  serverRoot: string;
  appointmentId: string;
  disabled?: boolean;
  isEmbeddedInApp?: boolean;
  variant?: ButtonProps["variant"];
  iconPosition?: "left" | "right";
  size?: ButtonProps["size"];
}

export const AddToCalendarButton: React.FunctionComponent<IProps> = ({
  serverRoot,
  appointmentId,
  disabled,
  isEmbeddedInApp = false,
  variant = "primary",
  iconPosition = "left",
  size = "md",
}) => {
  const { t } = useTranslation();
  const iconWidth = iconSize(size);
  return (
    <GrowingButton
      size={size}
      disabled={disabled}
      variant={variant}
      leftSection={
        iconPosition === "left" ? <IconArrowUpRight width={iconWidth} /> : null
      }
      rightSection={
        iconPosition === "right" ? <IconArrowUpRight width={iconWidth} /> : null
      }
      onClick={() => {
        if (isEmbeddedInApp && window.ReactNativeWebView) {
          // send data object to React Native (only string)
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ action: "addToCalendar", appointmentId })
          );
        }
        // trackEvent(TrackingEventName.SignupAddToCalendarClicked, {});
      }}
      data-testid="addToCalendarButton"
      href={
        !isEmbeddedInApp &&
        `${serverRoot}/appointment/${appointmentId}/event.ics`
      }
      buttonLabel={t("addToMyCalendar")}
    />
  );
};

const iconSize = (size: ButtonProps["size"]) => {
  switch (size) {
    case "xs":
    case "sm":
    case "compact-xs":
    case "compact-sm":
      return 16;
    default:
      return 20;
  }
};
