import { ReactComponent as Checkmark } from '@assets/icons/fi-rr-check.svg';
import { Box, Card, Group, Image, Stack, Text, UnstyledButton } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { PublicBookableScreeningAppointment, Role } from '@shared/gql/sdk';
import dayjs from 'dayjs';
import { FunctionComponent } from 'react';

import { env } from '../../src/env';
import { t } from '../../src/i18n';
import { colors } from '../theme';

type TimeSlotType = 'SELECTED' | 'CONFIRM';
interface IProps {
  type?: TimeSlotType;
  bookableAppointment: PublicBookableScreeningAppointment;
  onClick?: () => void;
}
export const TimeSlotCard: FunctionComponent<IProps> = ({ type, bookableAppointment, onClick }) => {
  const { width } = useViewportSize();
  const getTitleByRole = (role: Role) => {
    switch (role) {
      case Role.Doctor:
        return t('doctor');
      case Role.Nurse:
        return t('nurse');
      case Role.Coach:
        return t('coach');
    }
  };

  const startDate = dayjs(bookableAppointment.startTime);
  const endDate = dayjs(bookableAppointment.endTime);
  const date = `${startDate.format('L HH:mm')} - ${endDate.format('HH:mm')}`;

  const name = bookableAppointment.name;
  const title = getTitleByRole(bookableAppointment.userRole);
  const imageSrc = bookableAppointment.profilePictureImageId
    ? `${env.employeeImageAssets}/${bookableAppointment.profilePictureImageId}`
    : undefined;
  const isSelected = type === 'SELECTED';
  return (
    <UnstyledButton
      style={{
        borderRadius: type === 'CONFIRM' ? '32px' : '16px',
      }}
      onClick={onClick}
      m="xs"
    >
      <Card
        shadow={type ? 'sm' : undefined}
        bg={isSelected ? 'menthol.0' : 'neutral.0'}
        radius={type === 'CONFIRM' ? 'xl' : 'lg'}
        px="md"
        py="sm"
        withBorder={type === 'CONFIRM'}
        style={{
          border: isSelected ? `solid 1px ${colors.menthol[2]}` : undefined,
        }}
      >
        <Group justify="space-between">
          <Group gap={'sm'}>
            <Box w={40} h={40}>
              {imageSrc && <Image src={imageSrc} width={40} height={40} radius={20} />}
            </Box>
            <Stack gap={0}>
              <Text c="embla.4" size="md" fw={600} lh={'24px'}>
                {date}
              </Text>
              <Text c="embla.4" size="md">
                {t('nameAndTitle', {
                  name: name,
                  title: title,
                })}
              </Text>
            </Stack>
          </Group>
          {type && width > 342 && <Checkmark width={20} color={colors.menthol[3]} />}
        </Group>
      </Card>
    </UnstyledButton>
  );
};
