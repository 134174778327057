import { Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import { BackButton } from "@shared/components/buttons/BackButton";
import { NextButton } from "@shared/components/buttons/NextButton";
import { CheckboxCard } from "@shared/components/CheckboxCard";
import { StepTitle } from "@shared/components/StepTitle";
import { FunnelPageComponent } from "@shared/funnel-engine";
import { MotivationAnswersInput } from "@shared/gql/sdk";
import { Trans, useTranslation } from "react-i18next";

import IFunnelContext from "../FunnelContext";

export type Motivation = keyof MotivationAnswersInput;

type MotivationsForm = Partial<Record<Motivation, boolean>>;

export const MotivationsV2: FunnelPageComponent<
  Partial<MotivationsForm>,
  IFunnelContext
> = ({ data: motivations, funnelApi }) => {
  const { t } = useTranslation();
  const form = useForm<MotivationsForm>({
    initialValues: {
      FITNESS_AND_CONFIDENCE: motivations?.FITNESS_AND_CONFIDENCE || false,
      HEALTHIER_LIFESTYLE: motivations?.HEALTHIER_LIFESTYLE || false,
      IMPROVE_MENTAL_HEALTH: motivations?.IMPROVE_MENTAL_HEALTH || false,
      LOWER_RISK_OF_DISEASES: motivations?.LOWER_RISK_OF_DISEASES || false,
      MORE_ENERGY: motivations?.MORE_ENERGY || false,
      SUSTAINABLE_WEIGHT_LOSS: motivations?.SUSTAINABLE_WEIGHT_LOSS || false,
    },
  });

  return (
    <Stack>
      <StepTitle title={<Trans i18nKey="whichAreTheMostImportantToYou" />} />
      <Stack gap="sm">
        <CheckboxCard
          label={t("screeningQuestion.healthierLifestyle")}
          {...form.getInputProps("HEALTHIER_LIFESTYLE", {
            type: "checkbox",
          })}
        />
        <CheckboxCard
          label={t("screeningQuestion.fitnessAndConfidence")}
          {...form.getInputProps("FITNESS_AND_CONFIDENCE", {
            type: "checkbox",
          })}
        />
        <CheckboxCard
          label={t("screeningQuestion.lowerRiskOfDiseases")}
          {...form.getInputProps("LOWER_RISK_OF_DISEASES", {
            type: "checkbox",
          })}
        />
        <CheckboxCard
          label={t("screeningQuestion.moreEnergy")}
          {...form.getInputProps("MORE_ENERGY", { type: "checkbox" })}
        />
        <CheckboxCard
          label={t("screeningQuestion.sustainableWeightLoss")}
          {...form.getInputProps("SUSTAINABLE_WEIGHT_LOSS", {
            type: "checkbox",
          })}
        />
        <CheckboxCard
          label={t("screeningQuestion.improveMentalHealth")}
          {...form.getInputProps("IMPROVE_MENTAL_HEALTH", {
            type: "checkbox",
          })}
        />
      </Stack>
      <Stack my={"md"}>
        <NextButton
          disabled={!Object.values(form.values).some(Boolean)}
          onClick={() => funnelApi.next(form.values)}
        />
        <BackButton onClick={() => funnelApi.back()} />
      </Stack>
    </Stack>
  );
};
