import { Group, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { CountryCard } from '@shared/components/CountryCard';
import { EmblaTextInput } from '@shared/components/EmblaTextInput';
import { Address } from '@shared/gql/sdk';
import { resolveIntl } from '@shared/helpers';
import React, { FC, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface AddressFormProps {
  address: Address;
  label: string;
  onChange: (address: Address, valid: boolean) => void;
  ref: React.Ref<{ validate: () => boolean }>;
}

export const AddressForm: FC<AddressFormProps> = forwardRef(({ address, label, onChange }, ref) => {
  const { t } = useTranslation();
  const { country } = resolveIntl();
  const addressFormRef = useRef<HTMLDivElement>();
  useImperativeHandle(ref, () => ({
    validate: () => {
      const res = form.validate();
      if (res.hasErrors) {
        addressFormRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      return !res.hasErrors;
    },
  }));

  const form = useForm<Address>({
    validateInputOnBlur: true,
    initialValues: address,
    validate: {
      addressLine1: (value) => (value ? null : t('addressLine1Required')),
      city: (value) => (value ? null : t('cityRequired')),
      postalCode: (value) => (value ? null : t('postalCodeRequired')),
    },
  });

  useEffect(() => {
    onChange({ ...form.values, countryISO3166: country }, form.isValid());
  }, [onChange, country, form.values]);

  return (
    <Stack gap={0} ref={addressFormRef}>
      <Text c="embla.3" fw="bold">
        {`${label}*`}
      </Text>
      <EmblaTextInput placeholder={t('addressLine1')} {...form.getInputProps('addressLine1')} />
      <EmblaTextInput placeholder={t('addressLine2')} {...form.getInputProps('addressLine2')} />
      <Group gap="sm">
        <EmblaTextInput style={{ flex: 1 }} placeholder={t('postalCode')} {...form.getInputProps('postalCode')} />
        <EmblaTextInput style={{ flex: 2 }} placeholder={t('city')} {...form.getInputProps('city')} />
      </Group>
      <CountryCard />
    </Stack>
  );
});
