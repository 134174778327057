import { MouseEventHandler } from "react";

import { ReactComponent as IconArrowLeft } from "@assets/icons/fi-rr-arrow-left.svg";
import { colors } from "../../theme";
import { GrowingButton } from "./GrowingButton";

interface IProps {
  disabled?: boolean;
  onClick?: MouseEventHandler;
}

export const BackButton: React.FunctionComponent<IProps> = ({
  onClick,
  disabled,
}) => {
  return (
    <GrowingButton
      disabled={disabled}
      color={"cream.0"}
      leftSection={<IconArrowLeft width={20} color={colors["neutral"][4]} />}
      onClick={onClick}
    />
  );
};
