import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as IconArrowRight } from "@assets/icons/fi-rr-arrow-right.svg";
import { GrowingButton } from "./GrowingButton";

interface IProps {
  disabled?: boolean;
  loading?: boolean;
  onClick?: MouseEventHandler;
  type?: HTMLButtonElement["type"];
  label?: string;
}

export const NextButton: React.FunctionComponent<IProps> = ({
  onClick,
  type,
  disabled,
  loading,
  label,
}) => {
  const { t } = useTranslation();

  return (
    <GrowingButton
      loading={loading}
      data-testid="next"
      disabled={disabled}
      buttonLabel={label || t("next")}
      rightSection={<IconArrowRight width={20} />}
      type={type}
      onClick={onClick}
    />
  );
};
