import { Transition } from "@mantine/core";
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";

type IProps = {
  isMounted: boolean;
  totalTime: number;
  enterDuration: number;
  exitDuration: number;
};

export const EaseInAndOut: FunctionComponent<PropsWithChildren<IProps>> = ({
  children,
  isMounted,
  totalTime,
  exitDuration,
  enterDuration,
}) => {
  const [mounted, setMounted] = useState<boolean>(false);
  useEffect(() => {
    setMounted(isMounted);
    if (isMounted) {
      setTimeout(() => {
        setMounted(false);
      }, totalTime - exitDuration);
    }
  }, [isMounted]);

  return (
    <Transition
      mounted={mounted}
      transition="fade"
      duration={enterDuration}
      exitDuration={exitDuration}
      timingFunction="ease"
    >
      {(styles) => {
        return <div style={{ ...styles }}>{children}</div>;
      }}
    </Transition>
  );
};
