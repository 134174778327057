import CountryDK from '@assets/images/countries/DK.png';
import CountryGB from '@assets/images/countries/GB.png';
import { Avatar, Card, Group, Text } from '@mantine/core';
import { CountryIso3166 } from '@shared/gql/sdk';
import { colors } from '@shared/theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { resolveIntl } from 'src/resolveIntl';

interface CountryCardProps {
  country?: CountryIso3166;
}

const resolveCountryFlag = (country: CountryIso3166) => {
  switch (country) {
    case 'DK':
      return CountryDK;
    case 'GB':
      return CountryGB;
    default:
      return '';
  }
};

export const CountryCard: React.FC<CountryCardProps> = ({ country }) => {
  const { t } = useTranslation();
  const { country: defaultCountry } = resolveIntl();

  const resolveLabel = (country: CountryIso3166) => {
    switch (country) {
      case 'DK':
        return t('denmark');
      case 'GB':
        return t('unitedKingdom');
      default:
        return '';
    }
  };

  return (
    <Card my="xs">
      <Group align="center">
        <Avatar size={20} src={resolveCountryFlag(country ?? defaultCountry)} />
        <Text c={colors.neutral[6]} size={'18px'}>
          {resolveLabel(country ?? defaultCountry)}
        </Text>
      </Group>
    </Card>
  );
};
