import StephPortrait from '@assets/images/employees/steph.png';
import { Card, Checkbox, Group, Stack, Text, UnstyledButton } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { StepTitle } from '@shared/components/StepTitle';
import { TrustBox } from '@shared/components/TrustBox';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { Address } from '@shared/gql/sdk';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import IFunnelContext, { ProductSelection } from 'src/v3/FunnelContext';

import { AddressForm } from './AddressForm';
import { Benefits } from './Benefits';
import { MembershipSummary } from './MembershipSummary';
import { YourRecommendedMembership } from './YourRecommendedMembership';
import { SwitchProgram } from '../Program/SwitchProgram';

export const CheckoutOrBook: FunnelPageComponent<
  {
    address: Address;
    didSelectBooking: boolean;
    confirmation: boolean;
    selectedProduct: ProductSelection;
    productChanged?: boolean;
  },
  IFunnelContext
> = ({
  data: { address, didSelectBooking: initialDidSelectBooking, confirmation: initialConfirmation },
  context: { selectedProduct, selectedProductPriceInfo: price, eligibleFor },
  funnelApi,
}) => {
  const { t } = useTranslation();
  const shippingAddress = useRef(address);
  const addressFormRef = useRef<{ validate: () => boolean }>();

  const [didSelectBooking, setDidSelectBooking] = useState(
    initialDidSelectBooking !== undefined ? initialDidSelectBooking : false,
  );
  const [confirmation, setConfirmation] = useState(initialConfirmation);

  return (
    <Stack>
      <StepTitle title={<Trans i18nKey={'yourFutureSelfWillThankYou'} />} />
      <Stack gap="lg">
        <YourRecommendedMembership
          price={price}
          selectedProduct={selectedProduct}
          selected={!didSelectBooking}
          onClick={() => setDidSelectBooking(false)}
          compact
        />

        {eligibleFor === ProductSelection.MedicationAndCoaching && (
          <SwitchProgram
            productChanged={true}
            selectedProduct={selectedProduct}
            onClick={() => {
              funnelApi.next({
                selectedProduct:
                  selectedProduct == ProductSelection.CoachingOnly
                    ? ProductSelection.MedicationAndCoaching
                    : ProductSelection.CoachingOnly,
                productChanged: true,
                address: shippingAddress.current,
                didSelectBooking,
                confirmation,
              });
            }}
          />
        )}

        <Stack gap="0">
          <Text py="xs" c="embla.3" fw="bold">
            {t('or')}
          </Text>
          <BookingOptionCard selected={didSelectBooking} onClick={() => setDidSelectBooking(true)} />
        </Stack>

        {!didSelectBooking && (
          <>
            <AddressForm
              ref={addressFormRef}
              label={t('deliveryAddress')}
              address={address}
              onChange={(changedAddress) => {
                shippingAddress.current = changedAddress;
              }}
            />
            <MembershipSummary price={price} />
          </>
        )}

        <TrustBox />
        <Benefits selectedProduct={selectedProduct} />

        <Checkbox
          className="stripe-checkbox"
          radius="5"
          label={<strong>{t('checkoutConfirmationDescription')}</strong>}
          checked={confirmation}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(e: any) => {
            setConfirmation(e.target.checked);
          }}
        />

        <Stack gap={'xs'}>
          {didSelectBooking ? (
            <NextButton
              onClick={() =>
                funnelApi.next({ selectedProduct, address: shippingAddress.current, didSelectBooking, confirmation })
              }
              label={t('bookAFreeConsultation')}
              disabled={!confirmation}
            />
          ) : (
            <NextButton
              onClick={() => {
                const addressIsValid = addressFormRef?.current?.validate();
                if (addressIsValid) {
                  funnelApi.next({ selectedProduct, address: shippingAddress.current, didSelectBooking, confirmation });
                }
              }}
              label={t('Checkout')}
              disabled={!confirmation}
            />
          )}

          <BackButton onClick={() => funnelApi.back()} />
        </Stack>
      </Stack>
    </Stack>
  );
};

const BookingOptionCard = ({ selected, onClick }: { selected: boolean; onClick: () => void }) => {
  const { t } = useTranslation();

  const stackRef = useRef(null);
  const [imageHeight, setImageHeight] = useState('auto');

  useEffect(() => {
    const stackHeight = stackRef?.current.clientHeight;
    setImageHeight(stackHeight);
  }, []);

  return (
    <UnstyledButton onClick={onClick}>
      <Card>
        <Group align="flex-start" wrap="nowrap">
          <img
            src={StephPortrait}
            height={imageHeight}
            width={56}
            style={{ objectFit: 'cover', objectPosition: '100% 0', borderRadius: 8 }}
          />
          <Stack ref={stackRef} style={{ flex: 1 }} justify="flex-start" gap={0}>
            <Group justify="space-between">
              <Text fw={400} lh={'32px'} size={'24px'} c="embla.4">
                {t('notReadyNow')}
              </Text>
              <Checkbox checked={selected} />
            </Group>
            <Text fw={400} c="embla.4">
              <Trans
                i18nKey={'bookAFreeVideoCallToLearnMore'}
                components={{
                  highlight: <span style={{ fontWeight: 600 }} />,
                }}
              />
            </Text>
          </Stack>
        </Group>
      </Card>
    </UnstyledButton>
  );
};
