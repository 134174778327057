import { ReactComponent as IconEmail } from '@assets/icons/fi-rr-envelope.svg';
import { ReactComponent as IconPercentage } from '@assets/icons/fi-rr-percentage.svg';
import { Anchor, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { EmblaCheckbox } from '@shared/components/EmblaCheckbox';
import { EmblaPhoneInput } from '@shared/components/EmblaPhoneInput';
import { EmblaTextInput } from '@shared/components/EmblaTextInput';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { CountryIso3166 } from '@shared/gql/sdk';
import { resolveIntl, validateEmail, validatePhone } from '@shared/helpers';
import { useRecaptcha } from '@shared/useRecaptcha';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import IFunnelContext from '../FunnelContext';
import { LanguageSelect } from '../util/LanguageSelect';

export interface IUserRegistrationData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  coupon: string;
  confirmAge: boolean;
  termsOfService: boolean;
  emailMarketing: boolean;
  recaptchaToken: string;
}

export enum RegistrationErrors {
  PhoneOrEmailAlreadyInUse = 'phone-or-email-already-in-use',
}

type RecaptchaStatus = 'not-executed' | 'executed' | 'executing';

export const Registration: FunnelPageComponent<Partial<IUserRegistrationData>, IFunnelContext, RegistrationErrors> = ({
  data,
  context,
  funnelApi,
}) => {
  const { userSession } = context || {};
  const { t } = useTranslation();
  const hasSession = !!userSession;
  const [recaptchaStatus, setRecaptchaStatus] = useState<RecaptchaStatus>('not-executed');
  const { isReady, getToken } = useRecaptcha();

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: data,
    validate: {
      firstName: (value) => (value ? null : t('firstNameRequired')),
      lastName: (value) => (value ? null : t('lastNameRequired')),
      phone: (value) => {
        return validatePhone(value);
      },
      email: (value) => {
        return validateEmail(value);
      },
      confirmAge: (value) => (value ? null : t('needToConfirmAge')),
      termsOfService: (value) => (value ? null : t('needToAgreeToTerms')),
      recaptchaToken: (value) => {
        if (!isReady) return null;
        if (value) return null;
        return t('needToAgreeToTerms');
      },
    },
  });

  const handleRecaptcha = async () => {
    if (!isReady) {
      console.warn('Execute recaptcha not yet available');
      return;
    }

    try {
      if (recaptchaStatus !== 'executing') {
        setRecaptchaStatus('executing');
        const tokenResponse = await getToken('signup');
        if (tokenResponse) {
          form.setFieldValue('recaptchaToken', tokenResponse);
          setRecaptchaStatus('executed');
        } else {
          setRecaptchaStatus('not-executed');
          form.setFieldValue('recaptchaToken', null);
          form.setFieldValue('termsOfService', false);
        }
      }
    } catch (e) {
      setRecaptchaStatus('not-executed');
      form.setFieldValue('recaptchaToken', null);
      form.setFieldValue('termsOfService', false);
      console.error(e);
    }
  };

  useEffect(() => {
    if (!funnelApi.error) return;

    switch (funnelApi.error) {
      case RegistrationErrors.PhoneOrEmailAlreadyInUse:
        form.setFieldError('phone', t('emailOrPhoneAlreadyInUse.short'));
        form.setFieldError('email', t('emailOrPhoneAlreadyInUse.short'));
        break;
    }
  }, [funnelApi.error]);
  const { country } = resolveIntl();

  let termsHref = '';
  let privacyHref = '';

  switch (country) {
    case CountryIso3166.Dk: {
      termsHref = 'https://www.joinembla.com/vilkar';
      privacyHref = 'https://www.joinembla.com/dk/persondatapolitik';
      break;
    }
    case CountryIso3166.Gb: {
      termsHref = 'https://www.joinembla.com/uk/terms-conditions';
      privacyHref = 'https://www.joinembla.com/uk/privacy-policy';
      break;
    }
  }
  const getTitle = () => {
    if (!funnelApi.state?.hasPreviousPage) return t('firstAFewDetails');
    switch (context.flowVariant) {
      case 'booking':
        return t('enterYourDetailsToBookTheConversation');
      case 'watch-list-for-higher-dosage':
        return t('enterFewDetailsSoWeCanReachOutWhenHigherDosesAreAvailable');
      case 'watch-list-screening-capacity':
        return t('enterFewDetailsSoWeCanReachOutWhenScreeningsAreAvailable');
    }
  };

  return (
    <form
      onSubmit={form.onSubmit(() => {
        funnelApi.next(form.values);
      })}
    >
      <Stack>
        <StepTitle title={getTitle()} />
        <Stack gap={0}>
          {country === CountryIso3166.Dk ? <LanguageSelect /> : null}
          <EmblaTextInput
            withAsterisk
            placeholder={t('firstName')}
            {...form.getInputProps('firstName')}
            disabled={hasSession}
          />

          <EmblaTextInput
            withAsterisk
            placeholder={t('lastName')}
            {...form.getInputProps('lastName')}
            disabled={hasSession}
          />

          <EmblaTextInput
            type="email"
            withAsterisk
            placeholder={t('email')}
            {...form.getInputProps('email')}
            leftSection={<IconEmail width={20} />}
            disabled={hasSession}
          />
          <EmblaPhoneInput
            {...form.getInputProps('phone')}
            onPhoneNumberChange={(number) => {
              form.setValues({ phone: number });
            }}
            disabled={hasSession}
          />
          <EmblaTextInput
            withAsterisk
            placeholder={t('coupon')}
            {...form.getInputProps('coupon')}
            leftSection={<IconPercentage width={20} />}
          />

          <>
            <EmblaCheckbox
              mt="md"
              label={t('confirmAge')}
              {...form.getInputProps('confirmAge', { type: 'checkbox' })}
            />
            <EmblaCheckbox
              mt="md"
              label={
                <Text size={'md'} c={'embla.4'}>
                  <Trans
                    i18nKey="termsOfServiceAndPrivacy"
                    components={{
                      tc: (
                        <Anchor
                          href={termsHref}
                          c={'embla.4'}
                          style={{ textDecoration: 'underline' }}
                          fw={600}
                          target="_blank"
                        ></Anchor>
                      ),
                      pp: (
                        <Anchor
                          href={privacyHref}
                          c={'embla.4'}
                          style={{ textDecoration: 'underline' }}
                          fw={600}
                          target="_blank"
                        ></Anchor>
                      ),
                    }}
                  />
                </Text>
              }
              {...form.getInputProps('termsOfService', { type: 'checkbox' })}
              onChange={(e) => {
                if (isReady && e.currentTarget.checked) {
                  handleRecaptcha();
                }
                form.getInputProps('termsOfService', { type: 'checkbox' }).onChange(e);
              }}
            />
            <EmblaCheckbox
              mt="md"
              label={
                <Text size={'md'} c={'embla.4'}>
                  {t('emailMarketing')}
                </Text>
              }
              {...form.getInputProps('emailMarketing', { type: 'checkbox' })}
              onChange={(e) => {
                form.getInputProps('emailMarketing', { type: 'checkbox' }).onChange(e);
              }}
            />
          </>
        </Stack>
        <NextButton loading={recaptchaStatus === 'executing'} disabled={!form.isValid()} type={'submit'} />
        {funnelApi.state?.hasPreviousPage && <BackButton onClick={() => funnelApi.back()} />}
      </Stack>
    </form>
  );
};
