import { Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import { BackButton } from "@shared/components/buttons/BackButton";
import { NextButton } from "@shared/components/buttons/NextButton";
import { CheckboxCard } from "@shared/components/CheckboxCard";
import { StepTitle } from "@shared/components/StepTitle";
import { FunnelPageComponent } from "@shared/funnel-engine";
import { EligibilityAnswersInput } from "@shared/gql/sdk";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import IFunnelContext from "../FunnelContext";

type EligibilityQuestion = keyof EligibilityAnswersInput;
type SelfScreeningForm = Record<EligibilityQuestion, boolean>;

export const EligibilityQuestions: FunnelPageComponent<
  Partial<SelfScreeningForm>,
  IFunnelContext
> = ({ data: questions, funnelApi }) => {
  const { t } = useTranslation();
  const [none, setNone] = useState<boolean>(null);

  const form = useForm({
    initialValues: {
      diabetesType1: questions?.diabetesType1 || false,
      diabetesType2: questions?.diabetesType2 || false,
      pregnancy: questions?.pregnancy || false,
      cancer: questions?.cancer || false,
      alcohol_drugs: questions?.alcohol_drugs || false,
      eatingdisorder: questions?.eatingdisorder || false,
    },
  });

  return (
    <Stack>
      <StepTitle title={t("whichOfTheFollowingApplyToYou")} />
      <Stack gap="sm">
        <CheckboxCard
          label={t("screeningQuestion.diabetesType1")}
          {...form.getInputProps("diabetesType1", { type: "checkbox" })}
        />
        <CheckboxCard
          label={t("screeningQuestion.diabetesType2")}
          {...form.getInputProps("diabetesType2", { type: "checkbox" })}
        />
        <CheckboxCard
          label={t("screeningQuestion.pregnancy")}
          {...form.getInputProps("pregnancy", { type: "checkbox" })}
        />
        <CheckboxCard
          label={t("screeningQuestion.cancer")}
          {...form.getInputProps("cancer", { type: "checkbox" })}
        />
        <CheckboxCard
          label={t("screeningQuestion.alcohol_drugs")}
          {...form.getInputProps("alcohol_drugs", { type: "checkbox" })}
        />
        <CheckboxCard
          label={t("screeningQuestion.eatingdisorder")}
          {...form.getInputProps("eatingdisorder", { type: "checkbox" })}
        />
        <CheckboxCard
          label={t("screeningQuestion.noneOfTheAbove")}
          checked={none && Object.values(form.values).every((v) => !v)}
          onChange={(value) => {
            setNone(value);
            if (value) {
              form.setValues({
                diabetesType1: false,
                diabetesType2: false,
                pregnancy: false,
                cancer: false,
                alcohol_drugs: false,
                eatingdisorder: false,
              });
            }
          }}
        />
      </Stack>

      <NextButton
        disabled={!(Object.values(form.values).filter(Boolean).length || none)}
        onClick={() => funnelApi.next(form.values)}
      />
      <BackButton onClick={() => funnelApi.back()} />
    </Stack>
  );
};
