import { Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { CheckboxCard } from '@shared/components/CheckboxCard';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { useTranslation } from 'react-i18next';

import IFunnelContext from '../../FunnelContext';

type BiggestMotivatorAnswer =
  | 'CONFIDENCE_BOOST'
  | 'EMOTIONAL_WELLBEING'
  | 'HEALTH_CONCERNS'
  | 'PEACE_OF_MIND'
  | 'PREFER_NOT_TO_SAY'
  | 'QUALITYTIME_WITH_FAMILY'
  | 'TRAVEL_HOLIDAYS';

export const BiggestMotivator: FunnelPageComponent<{ answers: Array<BiggestMotivatorAnswer> }, IFunnelContext> = ({
  data: { answers },
  funnelApi,
}) => {
  const { t } = useTranslation();

  const translations: Record<BiggestMotivatorAnswer, string> = {
    HEALTH_CONCERNS: t('healthConcerns'),
    CONFIDENCE_BOOST: t('confidenceBoost'),
    EMOTIONAL_WELLBEING: t('emotionalWellbeing'),
    PEACE_OF_MIND: t('peaceOfMind'),
    QUALITYTIME_WITH_FAMILY: t('qualityTimeWithFamily'),
    TRAVEL_HOLIDAYS: t('travelHolidays'),
    PREFER_NOT_TO_SAY: t('preferNotToSay'),
  };

  const form = useForm({
    initialValues: {
      CONFIDENCE_BOOST: answers?.includes('CONFIDENCE_BOOST'),
      EMOTIONAL_WELLBEING: answers?.includes('EMOTIONAL_WELLBEING'),
      HEALTH_CONCERNS: answers?.includes('HEALTH_CONCERNS'),
      PEACE_OF_MIND: answers?.includes('PEACE_OF_MIND'),
      PREFER_NOT_TO_SAY: answers?.includes('PREFER_NOT_TO_SAY'),
      QUALITYTIME_WITH_FAMILY: answers?.includes('QUALITYTIME_WITH_FAMILY'),
      TRAVEL_HOLIDAYS: answers?.includes('TRAVEL_HOLIDAYS'),
    },
  });

  return (
    <Stack>
      <StepTitle title={t('whatsYourBiggestMotivator')} />
      <Stack gap="sm">
        {Object.keys(translations)
          .filter((key) => key != 'PREFER_NOT_TO_SAY')
          .map((key) => (
            <CheckboxCard
              key={key}
              label={translations[key as BiggestMotivatorAnswer]}
              {...form.getInputProps(key, { type: 'checkbox' })}
              onChange={(value) => {
                form.getInputProps(key, { type: 'checkbox' }).onChange(value);
                if (value) {
                  form.setFieldValue('PREFER_NOT_TO_SAY', false);
                }
              }}
            />
          ))}

        <CheckboxCard
          label={t('preferNotToSay')}
          {...form.getInputProps('PREFER_NOT_TO_SAY', {
            type: 'checkbox',
          })}
          onChange={(value) => {
            form.getInputProps('PREFER_NOT_TO_SAY', { type: 'checkbox' }).onChange(value);
            if (value) {
              form.setValues({
                CONFIDENCE_BOOST: false,
                EMOTIONAL_WELLBEING: false,
                HEALTH_CONCERNS: false,
                PEACE_OF_MIND: false,
                QUALITYTIME_WITH_FAMILY: false,
                TRAVEL_HOLIDAYS: false,
              });
            }
          }}
        />
      </Stack>

      <NextButton
        disabled={!Object.values(form.values).filter(Boolean).length}
        onClick={() => {
          const answers = Object.keys(form.values).filter(
            (key) => form.values[key as BiggestMotivatorAnswer],
          ) as Array<BiggestMotivatorAnswer>;
          funnelApi.next({ answers });
        }}
      />
      <BackButton onClick={() => funnelApi.back()} />
    </Stack>
  );
};
