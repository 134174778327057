import projectionGraphCoachingOnly from "@assets/weight-loss-prediction-coaching-only.svg";
import { Card, Group, Stack, Text } from "@mantine/core";
import { IndicatorWithLabel } from "@shared/components/IndicatorWithLabel";
import { StepTitle } from "@shared/components/StepTitle";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

export const Coaching: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Stack align="center" gap="lg">
      <StepTitle size="sm" title={t("loseWeightWithoutStarvingYourself")} />
      <Stack maw={460} w={"100%"} gap={0}>
        <WeightProjectionGraphCoachingOnly />
      </Stack>
    </Stack>
  );
};

const WeightProjectionGraphCoachingOnly: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Card w="100%" p={"lg"}>
      <Stack>
        <Group style={{ justifyContent: "center" }}>
          <Text fw={"bold"} size="xl" c="embla.4">
            {t("yourWeightLossJourney")}
          </Text>
        </Group>
        <Stack gap="xs">
          <Group w="100%" wrap="nowrap" align="stretch" gap={"sm"}>
            <div
              style={{
                display: "flex",
                paddingTop: 10,
                paddingBottom: 10,
                position: "relative",
                flex: 1,
              }}
            >
              <img width="100%" src={projectionGraphCoachingOnly} />
            </div>
          </Group>
        </Stack>

        {
          <Group justify="center">
            <IndicatorWithLabel
              indicatorColor="neutral.4"
              label={t("restrictiveDiet")}
              fw={600}
              size="md"
            />
            <IndicatorWithLabel
              indicatorColor="menthol.3"
              label={t("emblaCoaching")}
              fw={600}
              size="md"
            />
          </Group>
        }
      </Stack>
    </Card>
  );
};
