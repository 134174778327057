import { Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import { BackButton } from "@shared/components/buttons/BackButton";
import { NextButton } from "@shared/components/buttons/NextButton";
import { CheckboxCard } from "@shared/components/CheckboxCard";
import { StepTitle } from "@shared/components/StepTitle";
import { FunnelPageComponent } from "@shared/funnel-engine";
import { ComorbidityAnswersInput } from "@shared/gql/sdk";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import IFunnelContext from "../FunnelContext";

type ComorbidityQuestion = keyof ComorbidityAnswersInput;
type ComorbidityForm = Record<ComorbidityQuestion, boolean>;

export const ComorbidityQuestions: FunnelPageComponent<
  Partial<ComorbidityForm>,
  IFunnelContext
> = ({ data: questions, funnelApi }) => {
  const { t } = useTranslation();
  const [none, setNone] = useState<boolean>(null);

  const form = useForm({
    initialValues: {
      prediabetes: questions?.prediabetes || false,
      hypertension: questions?.hypertension || false,
      cardiovascular: questions?.cardiovascular || false,
      sleepapnea: questions?.sleepapnea || false,
      highcolesterol: questions?.highcolesterol || false,
    },
    validate: {
      prediabetes: (_, form) => {
        const values = Object.values(form);
        return !values.some(Boolean);
      },
    },
  });

  return (
    <Stack gap={"md"}>
      <StepTitle title={t("whichOfTheFollowingApplyToYou")} />
      <Stack gap="sm">
        <CheckboxCard
          label={t("screeningQuestion.prediabetes")}
          {...form.getInputProps("prediabetes", { type: "checkbox" })}
        />
        <CheckboxCard
          label={t("screeningQuestion.hypertension")}
          {...form.getInputProps("hypertension", { type: "checkbox" })}
        />
        <CheckboxCard
          label={t("screeningQuestion.cardiovascular")}
          {...form.getInputProps("cardiovascular", { type: "checkbox" })}
        />
        <CheckboxCard
          label={t("screeningQuestion.sleepapnea")}
          {...form.getInputProps("sleepapnea", { type: "checkbox" })}
        />
        <CheckboxCard
          label={t("screeningQuestion.highcolesterol")}
          {...form.getInputProps("highcolesterol", { type: "checkbox" })}
        />
        <CheckboxCard
          label={t("screeningQuestion.noneOfTheAbove")}
          checked={none && Object.values(form.values).every((v) => !v)}
          onChange={(value) => {
            setNone(value);
            if (value) {
              form.setValues({
                prediabetes: false,
                hypertension: false,
                cardiovascular: false,
                sleepapnea: false,
                highcolesterol: false,
              });
            }
          }}
        />
      </Stack>

      <NextButton
        disabled={!(Object.values(form.values).filter(Boolean).length || none)}
        onClick={() => funnelApi.next(form.values)}
      />
      <BackButton onClick={() => funnelApi.back()} />
    </Stack>
  );
};
