import { Radio, Stack } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { Disclaimer } from '@shared/components/Disclaimer';
import { RadioCard } from '@shared/components/RadioCard';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IFunnelContext from '../FunnelContext';

export const Pregnancy: FunnelPageComponent<{ value?: boolean }, IFunnelContext> = ({ data: { value }, funnelApi }) => {
  const { t } = useTranslation();
  const [isPregnantOrBreastfeeding, setIsPregnantOrBreastfeeding] = useState(value);

  return (
    <Stack gap={'md'}>
      <StepTitle title={t('areYouPregnantOrBreastfeeding')} />

      <Radio.Group
        value={isPregnantOrBreastfeeding === true ? 'yes' : isPregnantOrBreastfeeding === false ? 'no' : undefined}
      >
        <Stack gap="sm">
          <RadioCard value={'yes'} label={t('yes')} select={() => setIsPregnantOrBreastfeeding(true)} />
          <RadioCard value={'no'} label={t('no')} select={() => setIsPregnantOrBreastfeeding(false)} />
        </Stack>
      </Radio.Group>

      <Disclaimer type="info" pillText={t('medicalNotice')} message={t('pleaseBeAdvised')} />

      <NextButton
        disabled={isPregnantOrBreastfeeding == null}
        onClick={() => funnelApi.next({ value: isPregnantOrBreastfeeding })}
      />
      <BackButton onClick={() => funnelApi.back()} />
    </Stack>
  );
};
