import { ScrollArea, Stack, Text } from "@mantine/core";
import { BackButton } from "@shared/components/buttons/BackButton";
import { NextButton } from "@shared/components/buttons/NextButton";
import {
  DateSelector,
  DateWithAvailability,
} from "@shared/components/DateSelector";
import { Disclaimer } from "@shared/components/Disclaimer";
import { StepTitle } from "@shared/components/StepTitle";
import { TimeSelector } from "@shared/components/TimeSelector";
import { TimeSlotCard } from "@shared/components/TimeslotCard";
import { FunnelPageComponent } from "@shared/funnel-engine";
import { PublicBookableScreeningAppointment } from "@shared/gql/sdk";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import IFunnelContext from "../FunnelContext";

dayjs.extend(localizedFormat);

export enum PickTimeslotErrors {
  AppointmentNotAvailable = "appointment-not-available",
}

export const PickTimeslot: FunnelPageComponent<
  {
    selectedTimeslot?: PublicBookableScreeningAppointment;
  },
  IFunnelContext,
  PickTimeslotErrors
> = ({ context: { bookableAppointments }, funnelApi }) => {
  const { t } = useTranslation();
  const [selectedTimeslot, setSelectedTimeslot] =
    useState<PublicBookableScreeningAppointment>();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [selectedTime, setSelectedTime] = useState<string>();

  const [availableTimeslots, setAvailableTimeslots] = useState<
    PublicBookableScreeningAppointment[]
  >([]);

  const [timeOptions, setTimeOptions] = useState<string[]>([]);

  const datesWithAvailability: DateWithAvailability[] = useMemo(() => {
    const datesWithNonZeroAvailability = bookableAppointments.reduce<
      DateWithAvailability[]
    >((acc, d) => {
      const startOfDay = dayjs(d.startTime).startOf("day");
      const date = acc.find((a) => dayjs(a.date).isSame(startOfDay));
      if (date) {
        date.numberOfAvailableTimelots++;
      } else {
        acc.push({
          date: startOfDay.toDate(),
          numberOfAvailableTimelots: 1,
        });
      }
      return acc;
    }, []);

    return datesWithNonZeroAvailability.reduce<DateWithAvailability[]>(
      (acc, curr) => {
        let nextDay =
          acc?.length > 0
            ? dayjs(acc[acc.length - 1].date)
                .add(1, "day")
                .startOf("day")
            : dayjs(curr.date);

        if (curr.date.getTime() === nextDay.toDate().getTime()) {
          acc.push(curr);
        } else {
          while (curr.date.getTime() > nextDay.toDate().getTime()) {
            acc.push({ date: nextDay.toDate(), numberOfAvailableTimelots: 0 });
            nextDay = nextDay.add(1, "day");
          }
          acc.push(curr);
        }
        return acc;
      },
      [],
    );
  }, [bookableAppointments]);

  useEffect(() => {
    if (datesWithAvailability?.length) {
      setSelectedDate(
        dayjs(datesWithAvailability[0]?.date)
          .startOf("day")
          .toDate(),
      );
    }
  }, [datesWithAvailability]);

  useEffect(() => {
    const aps = bookableAppointments
      .filter((a) => {
        if (!selectedTime) {
          return;
        }
        const startTime = dayjs(a.startTime);

        let filterDate = dayjs(selectedDate);
        const [h, m] = selectedTime.split(":").map(parseFloat);
        filterDate = filterDate.set("hour", h);
        filterDate = filterDate.set("minute", m);

        return (
          startTime.isSame(filterDate, "date") &&
          startTime.isSame(filterDate, "hour") &&
          startTime.isSame(filterDate, "minute")
        );
      })
      .sort((d1, d2) => {
        return d1.endTime > d2.endTime ? 1 : -1;
      });
    setAvailableTimeslots(aps);
  }, [selectedDate, selectedTime]);

  useEffect(() => {
    const availableTimes = bookableAppointments
      .filter((a) => {
        const d = dayjs(a.startTime);
        return d.isSame(selectedDate, "date");
      })
      .sort((d1, d2) => {
        return d1.endTime > d2.endTime ? 1 : -1;
      })
      .map((t) => {
        const d = dayjs(t.startTime);
        return d.format("HH:mm");
      })
      .filter((value, index, array) => {
        return array.indexOf(value) === index;
      });

    setTimeOptions(availableTimes);
  }, [selectedDate]);

  useEffect(() => {
    setSelectedTime(null);
  }, [timeOptions]);

  useEffect(() => {
    setSelectedTimeslot(null);
  }, [selectedTime]);

  const appointmentDuration = bookableAppointments?.[0]
    ? dayjs(bookableAppointments[0].endTime).diff(
        bookableAppointments[0].startTime,
        "minutes",
      )
    : null;

  return (
    <Stack>
      {funnelApi.error === PickTimeslotErrors.AppointmentNotAvailable && (
        <Disclaimer
          type="warning"
          pillText={t("alreadyBooked")}
          message={t("theSelectedTimeslotHasBeenBookedPleaseSelectAnother")}
        />
      )}
      <StepTitle size="sm" title={t("yourWeightLossStartsWithAConsultation")} />

      <Stack gap="lg">
        <DateSelector
          setDate={setSelectedDate}
          data={datesWithAvailability}
          selected={selectedDate}
        />
        {selectedDate ? (
          <TimeSelector
            setTime={setSelectedTime}
            options={timeOptions}
            selected={selectedTime}
            durationInMinutes={appointmentDuration}
          />
        ) : null}
        {selectedTime ? (
          <Stack gap={"sm"}>
            <Text c={"embla.4"} fw={600} size={"lg"}>
              {t("selectAvailableAppointment")}
            </Text>
            <ScrollArea
              h={"100%"}
              type="auto"
              styles={{
                viewport: {
                  paddingRight: 0,
                },
              }}
            >
              <Stack gap="sm">
                {availableTimeslots?.map((app) => {
                  const isSelected =
                    app.startTime === selectedTimeslot?.startTime &&
                    app.calendarId === selectedTimeslot?.calendarId;
                  if (availableTimeslots.length === 1 && !isSelected) {
                    setSelectedTimeslot(app);
                  }

                  return (
                    <TimeSlotCard
                      key={`${app.calendarId}-${app.startTime}`}
                      bookableAppointment={app}
                      type={isSelected ? "SELECTED" : null}
                      onClick={() => {
                        setSelectedTimeslot(isSelected ? null : app);
                      }}
                    />
                  );
                })}
              </Stack>
            </ScrollArea>
          </Stack>
        ) : null}
      </Stack>
      <NextButton
        disabled={!selectedTimeslot}
        onClick={() => funnelApi.next({ selectedTimeslot })}
      />
      <BackButton onClick={() => funnelApi.back()} />
    </Stack>
  );
};
