import { Text, Group, Image, Progress, Stack } from "@mantine/core";
import { FunctionComponent } from "react";
import emblaSvg from "@assets/embla.svg";

export const TopBar: FunctionComponent<{
  sections: { label: string; percentComplete: number }[];
}> = ({ sections }) => {
  return (
    <Stack mb={"md"} w="100%" h={80} gap={"lg"} mt={"sm"}>
      <Group justify="center">
        <Image src={emblaSvg} height={21} width={80} />
      </Group>

      <Group grow gap={"xs"}>
        {sections.map((item, index) => (
          <TopBarItem key={`${item.label}-${index}`} {...item} />
        ))}
      </Group>
    </Stack>
  );
};

interface ITopBarItem {
  label: string;
  percentComplete: number;
}

const TopBarItem = ({ label, percentComplete }: ITopBarItem) => {
  return (
    <Stack gap={"xs"}>
      <Progress value={percentComplete} size={"xs"} />
      <Text ta="center" c="embla.4" size={"xs"} fw={600}>
        {label}
      </Text>
    </Stack>
  );
};
