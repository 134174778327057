import { Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { CheckboxCard } from '@shared/components/CheckboxCard';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { ComorbidityAnswersInput } from '@shared/gql/sdk';
import { useTranslation } from 'react-i18next';

import IFunnelContext from '../FunnelContext';

type ComorbidityQuestion = keyof ComorbidityAnswersInput;
type ComorbidityForm = Record<ComorbidityQuestion, boolean> & { none: boolean };

export const ComorbidityQuestionsForCheckout: FunnelPageComponent<Partial<ComorbidityForm>, IFunnelContext> = ({
  data: questions,
  funnelApi,
}) => {
  const { t } = useTranslation();

  const form = useForm({
    initialValues: {
      prediabetes: questions?.prediabetes || false,
      hypertension: questions?.hypertension || false,
      cardiovascular: questions?.cardiovascular || false,
      sleepapnea: questions?.sleepapnea || false,
      highcolesterol: questions?.highcolesterol || false,
      none: Object.values(questions).length > 0 && Object.values(questions).every((qv) => !qv),
    },
    validate: {
      prediabetes: (_, form) => {
        const values = Object.values(form);
        return !values.some(Boolean);
      },
    },
  });

  const onQuestionChange = (formKey: keyof Omit<typeof form.values, 'none'>, value: boolean) => {
    form.getInputProps(formKey, { type: 'checkbox' }).onChange(value);
    if (value) {
      form.setFieldValue('none', false);
    }
  };

  return (
    <Stack gap={'md'}>
      <StepTitle title={t('haveYouEverBeenDiagnosedWithAnyOfTheFollowing')} />
      <Stack gap="sm">
        <CheckboxCard
          label={t('prediabetes')}
          {...form.getInputProps('prediabetes', { type: 'checkbox' })}
          onChange={(value) => onQuestionChange('prediabetes', value)}
        />
        <CheckboxCard
          label={t('highBloodPressure')}
          {...form.getInputProps('hypertension', { type: 'checkbox' })}
          onChange={(value) => onQuestionChange('hypertension', value)}
        />
        <CheckboxCard
          label={t('cardiovascularDisease')}
          {...form.getInputProps('cardiovascular', { type: 'checkbox' })}
          onChange={(value) => onQuestionChange('cardiovascular', value)}
        />
        <CheckboxCard
          label={t('sleepApnea')}
          {...form.getInputProps('sleepapnea', { type: 'checkbox' })}
          onChange={(value) => onQuestionChange('sleepapnea', value)}
        />
        <CheckboxCard
          label={t('raisedCholesterolLevels')}
          {...form.getInputProps('highcolesterol', { type: 'checkbox' })}
          onChange={(value) => onQuestionChange('highcolesterol', value)}
        />
        <CheckboxCard
          label={t('noneOfTheAbove')}
          {...form.getInputProps('none', {
            type: 'checkbox',
          })}
          onChange={(value) => {
            form.getInputProps('none', { type: 'checkbox' }).onChange(value);
            if (value) {
              form.setValues({
                prediabetes: false,
                hypertension: false,
                cardiovascular: false,
                sleepapnea: false,
                highcolesterol: false,
              });
            }
          }}
        />
      </Stack>

      <NextButton
        disabled={!Object.values(form.values).filter(Boolean).length}
        onClick={() => {
          const formValues = { ...form.values };
          delete formValues.none;
          funnelApi.next(formValues);
        }}
      />
      <BackButton onClick={() => funnelApi.back()} />
    </Stack>
  );
};
