import api, { isAuthenticated } from '@shared/api';
import { FunnelEngineData } from '@shared/funnel-engine';
import IFunnelContext from 'src/v3/FunnelContext';

import { createV3FunnelConfig } from '../config';

export type Data = FunnelEngineData<ReturnType<typeof createV3FunnelConfig>['config']>;

export const saveData = (data: Data, context: IFunnelContext) => {
  if (!isAuthenticated()) return;

  try {
    const { eligibility, checkout } = data;
    api.SignupFunnelFinalizeV3({
      data: {
        emailMarketing: eligibility.registration.emailMarketing,
        smsMarketing: eligibility.registration.emailMarketing,
        glp1: eligibility.glp1.value,
        comorbidity: eligibility.comorbidity,
        eligibility: eligibility.eligibility,
        height_weight: eligibility.height_weight,
        signupCurrentMedication: eligibility.current_medications,
        sexAssignedAtBirth: eligibility.sex_assigned.value,
        signupPregnantOrBreastfeeding: eligibility.pregnancy.value,
        signupMedicationWanted: eligibility.medication_wanted.value,
        address: checkout.checkout_or_book.address,
        notEligibleReason: context.notEligibleReason,
      },
      terminal: false,
    });
  } catch (e) {
    console.warn('Failed to save data');
  }
};
