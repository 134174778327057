import { Badge, Card, Group, Stack, Text } from "@mantine/core";
import { FunctionComponent } from "react";

interface IProps {
  name: string;
  beforePrice?: string;
  price: string;
  priceSuffix?: string;
  priceDetails?: string;
  footer?: React.ReactNode;
  discountText?: string;
}

export const SimpleProductCard: FunctionComponent<IProps> = ({
  discountText,
  name,
  beforePrice,
  price,
  priceSuffix,
  priceDetails,
  footer,
}) => {
  return (
    <Card bg="menthol.0" py="lg">
      <Stack gap="sm" align="center">
        {discountText && (
          <Badge px="xl" fz="sm" color="success.3" size="xl">
            {discountText}
          </Badge>
        )}
        <Text ta="center" c="embla.4" size="lg" fw={600}>
          {name}
        </Text>
        <Group justify="center" align="baseline" gap="xs">
          {beforePrice && (
            <Text td="line-through" c="embla.3">
              {beforePrice}
            </Text>
          )}
          <Text c="embla.3" size="24px" lh={"32px"} fw={700}>
            {price}
          </Text>
          {priceSuffix && <Text c="embla.4">{priceSuffix}</Text>}
          {priceDetails && <Text c="embla.4">{priceDetails}</Text>}
        </Group>
        {footer}
      </Stack>
    </Card>
  );
};
