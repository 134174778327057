import { Group, Stack } from "@mantine/core";
import { colors, fontSizes } from "@shared/theme";
import React from "react";
import { useTranslation } from "react-i18next";

import { EmblaLink } from "./EmblaLink";

const manageCookies = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
) => {
  event.preventDefault();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any)?.Cookiebot?.renew();
};

const spanStyle = {
  fontSize: fontSizes["xs"],
  color: colors.neutral[5],
};

const StandardFooter: React.FC<Record<string, never>> = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        paddingTop: "8px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack align="center" justify="center" gap="sm">
        <span style={spanStyle}>
          {t("thisSiteIsProtectedByRecaptchaEnterprise")}
        </span>
        <Group align="center" justify="center" style={spanStyle} gap={4}>
          {t("theGoogle")}
          <EmblaLink href="https://policies.google.com/privacy" size="sm">
            {t("recaptchaPrivacyPolicy")}
          </EmblaLink>
          {t("and")}
          <EmblaLink href="https://policies.google.com/terms" size="sm">
            {t("recaptchaTermsOfService")}
          </EmblaLink>
          {t("apply")}
        </Group>
        <EmblaLink href="#" size="sm" onClick={manageCookies}>
          {t("linkText.manageCookies")}
        </EmblaLink>
      </Stack>
    </div>
  );
};

export default StandardFooter;
