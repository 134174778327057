import {
  FunctionComponent,
  HTMLAttributeAnchorTarget,
  PropsWithChildren,
} from "react";
import classes from "./css-modules/EmblaLink.module.css";

interface IProps {
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  size?: "md" | "sm";
  target?: HTMLAttributeAnchorTarget;
}

export const EmblaLink: FunctionComponent<PropsWithChildren<IProps>> = ({
  href,
  onClick,
  size = "md",
  target,
  children,
}) => {
  return (
    <a
      target={target}
      href={href}
      onClick={onClick}
      className={`${classes.emblaLink} ${size === "sm" ? classes.sm : ""}`}
    >
      {children}
    </a>
  );
};
