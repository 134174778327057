import api from "@shared/api";
import { useCallback, useEffect, useState } from "react";

import { initializeFromURL, initializeSession } from "../util/helpers";

const searchParams = new URLSearchParams(window.location.search);
const useSessionToken = () => {
  const [token, setToken] = useState<string>();
  const [initialized, setInitialized] = useState(false);

  const initialize = useCallback(async () => {
    const email = searchParams.get("email");
    const embedded = searchParams.get("app") === "true";

    const handleEvent: (
      this: Window,
      ev: MessageEvent<{ token: string }>,
    ) => void = ({ data }) => {
      const token = data?.token;
      if (token) {
        setToken(token);
        setInitialized(true);
        window.removeEventListener("message", handleEvent);
      }
    };

    if (embedded && window.ReactNativeWebView) {
      window.addEventListener("message", handleEvent);
      window.ReactNativeWebView.postMessage(JSON.stringify({ loaded: true }));
    } else if (email) {
      const response = await api.UserGetIncompleteSignUpToken({ email });
      if (response.UserGetSignInFlow?.signUpToken) {
        setToken(response.UserGetSignInFlow.signUpToken);
      }
      setInitialized(true);
    } else {
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    void initialize();
  }, [initialize]);

  return { initialized, token };
};

export const useUserSession = () => {
  const { token, initialized: tokenInitialized } = useSessionToken();
  const [initialized, setInitialized] = useState(false);
  const [session, setSession] = useState<
    Awaited<ReturnType<typeof initializeSession>>
  >({ context: initializeFromURL(), user: undefined });

  useEffect(() => {
    const initialize = async () => {
      if (token) {
        const response = await initializeSession(token);
        setSession((session) => ({
          context: { ...session.context, ...response.context },
          user: response.user,
        }));
        setInitialized(true);
      }
    };

    void initialize();
  }, [token]);

  return { session, initialized: initialized || (tokenInitialized && !token) };
};
