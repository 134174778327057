import { Stack } from "@mantine/core";
import { BackButton } from "@shared/components/buttons/BackButton";
import { NextButton } from "@shared/components/buttons/NextButton";
import { FunnelPageComponent } from "@shared/funnel-engine";

import { CoachingAndMedication } from "./CoachingAndMedicationChart";
import { Coaching } from "./CoachingOnlyChart";
import IFunnelContext, { ProductSelection } from "../../FunnelContext";

export const EmblaMethodChart: FunnelPageComponent<
  // eslint-disable-next-line @typescript-eslint/ban-types
  {},
  IFunnelContext,
  undefined
> = ({ funnelApi, context }) => {
  const {
    selectedProduct,
    measurements: { weight, preferredUnitSystem: unitSystem },
  } = context || {};

  return (
    <Stack align="center" gap="lg">
      {selectedProduct === ProductSelection.CoachingAndMaybeMedication ||
      selectedProduct === ProductSelection.MedicationAndCoaching ? (
        <CoachingAndMedication weight={weight} unitSystem={unitSystem} />
      ) : selectedProduct === ProductSelection.CoachingOnly ? (
        <Coaching />
      ) : null}

      <Stack>
        <NextButton onClick={() => funnelApi.next({})} />
        <BackButton onClick={() => funnelApi.back()} />
      </Stack>
    </Stack>
  );
};
