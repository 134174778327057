import { Box, Group, Text, MantineSize } from "@mantine/core";
import { FunctionComponent } from "react";
import { CustomColors } from "../theme";
interface IProps {
  label: string;
  color?: CustomColors;
  size?: MantineSize;
}
export const LeftLabel: FunctionComponent<React.PropsWithChildren<IProps>> = ({
  children,
  label,
  color = "embla.4",
  size,
}) => {
  return (
    <Group justify="space-between" align="center">
      <Text c={color} size={size} fw={600}>
        {label}
      </Text>
      <Box>{children}</Box>
    </Group>
  );
};
