import { Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { CheckboxCard } from '@shared/components/CheckboxCard';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { EligibilityAnswersInput } from '@shared/gql/sdk';
import { useTranslation } from 'react-i18next';

import IFunnelContext from '../FunnelContext';

type EligibilityQuestion = keyof EligibilityAnswersInput;
type SelfScreeningForm = Record<EligibilityQuestion, boolean> & { none: boolean };

export const EligibilityQuestionsForCheckout: FunnelPageComponent<Partial<SelfScreeningForm>, IFunnelContext> = ({
  data: questions,
  funnelApi,
}) => {
  const { t } = useTranslation();

  const form = useForm({
    initialValues: {
      diabetesType1: questions?.diabetesType1 || false,
      heartFailure: questions?.heartFailure || false,
      severeLiverOrKidneyDisease: questions?.severeLiverOrKidneyDisease || false,
      multipleEndocrineNeoplasiaType2: questions?.multipleEndocrineNeoplasiaType2 || false,
      historyOfInflammatoryBowelDiseaseOrGastroparesis:
        questions?.historyOfInflammatoryBowelDiseaseOrGastroparesis || false,
      cancer: questions?.cancer || false,
      activePancreatitisOrGallstones: questions?.activePancreatitisOrGallstones || false,
      eatingdisorder: questions?.eatingdisorder || false,
      alcohol_drugs: questions?.alcohol_drugs || false,
      none: Object.values(questions).length > 0 && Object.values(questions).every((qv) => !qv),
    },
  });

  const onQuestionChange = (formKey: keyof Omit<typeof form.values, 'none'>, value: boolean) => {
    form.getInputProps(formKey, { type: 'checkbox' }).onChange(value);
    if (value) {
      form.setFieldValue('none', false);
    }
  };

  return (
    <Stack>
      <StepTitle title={t('haveYouEverBeenDiagnosedWith')} />
      <Stack gap="sm">
        <CheckboxCard
          label={t('diabetesType1')}
          {...form.getInputProps('diabetesType1', { type: 'checkbox' })}
          onChange={(value) => onQuestionChange('diabetesType1', value)}
        />
        <CheckboxCard
          label={t('heartFailure')}
          {...form.getInputProps('heartFailure', { type: 'checkbox' })}
          onChange={(value) => onQuestionChange('heartFailure', value)}
        />
        <CheckboxCard
          label={t('severeLiverOrKidneyDisease')}
          {...form.getInputProps('severeLiverOrKidneyDisease', {
            type: 'checkbox',
          })}
          onChange={(value) => onQuestionChange('severeLiverOrKidneyDisease', value)}
        />
        <CheckboxCard
          label={t('multipleEndocrineNeoplasiaType2')}
          {...form.getInputProps('multipleEndocrineNeoplasiaType2', {
            type: 'checkbox',
          })}
          onChange={(value) => onQuestionChange('multipleEndocrineNeoplasiaType2', value)}
        />
        <CheckboxCard
          label={t('historyOfInflammatoryBowelDiseaseOrGastroparesis')}
          {...form.getInputProps('historyOfInflammatoryBowelDiseaseOrGastroparesis', { type: 'checkbox' })}
          onChange={(value) => onQuestionChange('historyOfInflammatoryBowelDiseaseOrGastroparesis', value)}
        />
        <CheckboxCard
          label={t('activeCancer')}
          {...form.getInputProps('cancer', { type: 'checkbox' })}
          onChange={(value) => onQuestionChange('cancer', value)}
        />
        <CheckboxCard
          label={t('activePancreatitisOrGallstones')}
          {...form.getInputProps('activePancreatitisOrGallstones', {
            type: 'checkbox',
          })}
          onChange={(value) => onQuestionChange('activePancreatitisOrGallstones', value)}
        />
        <CheckboxCard
          label={t('eatingdisorder')}
          {...form.getInputProps('eatingdisorder', {
            type: 'checkbox',
          })}
          onChange={(value) => onQuestionChange('eatingdisorder', value)}
        />
        <CheckboxCard
          label={t('alcoholDrugAbuseOrDependency')}
          {...form.getInputProps('alcohol_drugs', {
            type: 'checkbox',
          })}
          onChange={(value) => onQuestionChange('alcohol_drugs', value)}
        />
        <CheckboxCard
          label={t('screeningQuestion.noneOfTheAbove')}
          {...form.getInputProps('none', {
            type: 'checkbox',
          })}
          onChange={(value) => {
            form.getInputProps('none', { type: 'checkbox' }).onChange(value);
            if (value) {
              form.setValues({
                diabetesType1: false,
                heartFailure: false,
                severeLiverOrKidneyDisease: false,
                multipleEndocrineNeoplasiaType2: false,
                historyOfInflammatoryBowelDiseaseOrGastroparesis: false,
                cancer: false,
                activePancreatitisOrGallstones: false,
                eatingdisorder: false,
                alcohol_drugs: false,
              });
            }
          }}
        />
      </Stack>

      <NextButton
        disabled={!Object.values(form.values).filter(Boolean).length}
        onClick={() => {
          const formValues = { ...form.values };
          delete formValues.none;
          funnelApi.next(formValues);
        }}
      />
      <BackButton onClick={() => funnelApi.back()} />
    </Stack>
  );
};
