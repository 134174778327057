import { Radio, RadioProps } from "@mantine/core";
import { FunctionComponent } from "react";

import { ReactComponent as CheckIcon } from "@assets/icons/fi-rr-check.svg";

export const EmblaRadio: FunctionComponent<RadioProps> = (props) => {
  return (
    <Radio
      icon={CheckIcon}
      {...props}
      styles={{
        root: {
          marginLeft: 3,
        },
        radio: {
          borderColor: "var(--mantine-color-neutral-3)",
        },
        icon: {
          height: 16,
          width: 16,
          top: 4,
          left: 4,
          margin: "unset",
          transition: "transform .1s ease,opacity .1s ease",
        },
        error: {
          color: "var(--mantine-color-error-3)",
          fontSize: "var(--mantine-color-font-size-sm)",
        },
        label: {
          color: "var(--mantine-color-embla-4)",
          fontSize: "var(--mantine-color-font-size-md)",
          fontWeight: 400,
          paddingLeft: 12,
        },
        body: {
          alignItems: "center",
        },
      }}
    />
  );
};
