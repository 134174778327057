import { FunctionComponent } from "react";
import { Flex, Image } from "@mantine/core";
import AppConsultationImageEn from "@assets/images/app_consultation_en.png";
import AppConsultationImageDa from "@assets/images/app_consultation_da.png";
import AppHealthLogImageEn from "@assets/images/app_healthlog_en.png";
import AppHealthLogImageDa from "@assets/images/app_healthlog_da.png";
import { LocaleIso6391 } from "@shared/gql/sdk";

const ConsultationImageMap = {
  [LocaleIso6391.Da]: AppConsultationImageDa,
  [LocaleIso6391.En]: AppConsultationImageEn,
};

const HealthLogImageMap = {
  [LocaleIso6391.Da]: AppHealthLogImageDa,
  [LocaleIso6391.En]: AppHealthLogImageEn,
};

interface IProps {
  type?: "consultation" | "healthlog";
  locale?: LocaleIso6391;
}

export const PhoneImage: FunctionComponent<IProps> = ({
  type = "consultation",
  locale = LocaleIso6391.En,
}) => {
  return (
    <Flex justify="center">
      <Image width={250} src={getImage(type, locale)} />
    </Flex>
  );
};

const getImage = (type: IProps["type"], locale: LocaleIso6391) => {
  switch (type) {
    case "consultation":
      return ConsultationImageMap[locale];
    case "healthlog":
      return HealthLogImageMap[locale];
  }
};
