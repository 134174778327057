import emblaSvg from "@assets/embla.svg";
import { Box, Group, Image, Stack } from "@mantine/core";
import StandardFooter from "@shared/components/StandardFooter";
import { Funnel } from "@shared/funnel-engine";
import { useRef } from "react";

import { createNotifyMeFunnelConfig } from "./configs/notify-me/config";

const NotifyMeFunnel: React.FunctionComponent = () => {
  const funnelConfig = useRef(createNotifyMeFunnelConfig()).current;

  return (
    <Group w="100%" align="center" justify="center">
      <Stack style={{ flex: 1 }} maw={600} pt="md">
        <Box mt="xl">
          <Funnel
            name="notify-me"
            options={funnelConfig}
            sectionDisplay={() => (
              <Group justify="center" mb="xl">
                <Image src={emblaSvg} height={21} width={80} />
              </Group>
            )}
            footerDisplay={() => <StandardFooter />}
          />
        </Box>
      </Stack>
    </Group>
  );
};

export default NotifyMeFunnel;
