import { ReactComponent as AppStore } from '@assets/app-store-badge.svg';
import { ReactComponent as GooglePlay } from '@assets/google-play-badge.svg';
import { Anchor, Flex } from '@mantine/core';
import { OS, useOs } from '@mantine/hooks';
import { FunctionComponent, ReactNode } from 'react';

interface IStore {
  url: string;
  component: ReactNode;
}

const iosUrl = 'https://apps.apple.com/app/embla/id1607818562';
const ios = {
  url: iosUrl,
  component: <AppStore width={200} />,
};

const androidUrl = 'https://play.google.com/store/apps/details?id=com.joinembla.embla1';
const android = {
  url: androidUrl,
  component: <GooglePlay width={200} />,
};

const downloadMap: Record<OS, IStore> = {
  ios: ios,
  macos: ios,
  android: android,
  windows: android,
  linux: android,
  undetermined: android,
};

export const AppButton: FunctionComponent<{ os?: OS }> = ({ os }) => {
  const detectedOS = useOs();
  const store = downloadMap[os || detectedOS];

  return (
    <Flex justify="center" align="center" h={'65'} style={{ overflowY: 'hidden' }}>
      <Anchor mt="sm" href={store.url} target="_blank">
        {store.component}
      </Anchor>
    </Flex>
  );
};
