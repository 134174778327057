import { Card, CheckboxProps, Text } from "@mantine/core";
import { FunctionComponent } from "react";
import { EmblaCheckbox } from "./EmblaCheckbox";

export const CheckboxCard: FunctionComponent<
  Omit<CheckboxProps, "onChange"> & { onChange: (value: boolean) => void }
> = ({ onChange, ...props }) => {
  return (
    <Card
      shadow="xs"
      bg={props.checked ? "menthol.0" : "neutral.0"}
      onClick={() => {
        onChange(!props.checked);
      }}
    >
      <EmblaCheckbox
        lh={24}
        checked={props.checked}
        readOnly
        label={
          <Text
            c="neutral.6"
            fw={600}
            onClick={(event) => event.stopPropagation()}
          >
            {props.label}
          </Text>
        }
      />
    </Card>
  );
};
