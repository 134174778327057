import './index.css';
import './App.css';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import 'dayjs/locale/da';
import 'dayjs/locale/en-gb';

import * as Sentry from '@sentry/react';
import { DefaultContexts } from '@shared/DefaultContexts.tsx';
import { LocaleIso6391 } from '@shared/gql/sdk';
import { resolveIntl } from '@shared/helpers.ts';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './App.tsx';
import i18n from './i18n';
import { openInApp } from './open-in-app.ts';
import { IFunnelSession } from './v3/FunnelContext.ts';
import FunnelDK from './v3/FunnelDK.tsx';
import NotifyMeFunnel from './v3/FunnelNotifyMe.tsx';
import FunnelUKWithCheckoutOrBookComponent from './v3/FunnelUKWithCheckoutOrBook.tsx';

const { locale } = resolveIntl();
dayjs.extend(localizedFormat);

if (locale === LocaleIso6391.Da) {
  dayjs.locale('da');
} else if (locale === LocaleIso6391.En) {
  dayjs.locale('en-gb');
}

Sentry.init({
  dsn: 'https://bed0fb5a2f4c45209f1c4bffbac6c0f8@o4503966019616768.ingest.sentry.io/4505356968984576',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/web\.joinembla\.com/],
      tracePropagationTargets: [/dev-app\.joinembla\com/, /app\.joinembla\com/],
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: ['https://dev-app.joinembla.com/graphql', 'https://app.joinembla.com/graphql'],
    }),
  ],
  enabled: import.meta.env['VITE_SENTRY_ENABLED'] === 'true',
  environment: import.meta.env['VITE_SENTRY_ENVIRONMENT'],
  // performance Monitoring
  tracesSampleRate: parseFloat(import.meta.env['VITE_SENTRY_SAMPLE_RATE']) || 0,
  // session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

let funnelComponent: FunctionComponent<{ session: IFunnelSession }>;
const { pathname, href } = window.location;
const isAppLink = pathname.slice(0, 4) === '/app';
const isAppLinkWithCountryCode = pathname.includes('/dk/app') || pathname.includes('/uk/app');

if (isAppLink || isAppLinkWithCountryCode) {
  openInApp(href);
} else if (pathname.includes('/uk/signup/notify-me')) {
  funnelComponent = NotifyMeFunnel;
} else if (pathname.includes('/uk/signup')) {
  funnelComponent = FunnelUKWithCheckoutOrBookComponent;
} else if (pathname.includes('/dk/signup')) {
  funnelComponent = FunnelDK;
} else {
  window.location.pathname = '/uk/signup';
}

if (funnelComponent) {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <DefaultContexts i18n={i18n}>
      <App funnelComponent={funnelComponent} />
    </DefaultContexts>,
  );
}
