import { ReactComponent as IconCheck } from '@assets/icons/fi-rr-check.svg';
import { ReactComponent as IconEmail } from '@assets/icons/fi-rr-envelope.svg';
import { ReactComponent as IconPhone } from '@assets/icons/fi-rr-phone-call.svg';
import { ReactComponent as IconCamera } from '@assets/icons/fi-rr-video-camera-alt.svg';
import { Stack, Stepper, Text } from '@mantine/core';
import { AddToCalendarButton } from '@shared/components/buttons/AddToCalendarButton';
import { DownloadButton } from '@shared/components/buttons/DownloadButton';
import { GrowingButton } from '@shared/components/buttons/GrowingButton';
import { PhoneImage } from '@shared/components/PhoneImage';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { getRootPath } from '@shared/helpers';
import { colors } from '@shared/theme';
import { FunctionComponent } from 'react';

import { env } from '../../env';
import { t } from '../../i18n';
import IFunnelContext from '../FunnelContext';

export const Confirmation: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ context }) => {
  const { serverRoot } = env || { serverRoot: 'https://app-joinembla.com' };
  const { flowVariant, appointmentId, locale } = context;
  const isEmbeddedInApp = context.embedded;
  const { topText, title, lgText } = useConfirmationContent(flowVariant);
  const showDownloadButton = !isEmbeddedInApp;

  const showAppointmentInfo = flowVariant === 'booking';

  return (
    <Stack gap="lg" py="md">
      <Text ta="center" c="menthol.3" fw={700}>
        {topText}
      </Text>
      <StepTitle mt={0} mb={0} title={title} />
      {lgText && (
        <Text ta="center" size="lg" c="embla.3" fw={600}>
          {lgText}
        </Text>
      )}
      {showAppointmentInfo ? (
        <Stack>
          <Text ta="center" c="embla.4" fw={400} size="lg">
            {t('heresWhatToExpect')}
          </Text>

          <ConfirmationStepper email={context.userSession.email} isEmbeddedInApp={isEmbeddedInApp} />

          {isEmbeddedInApp ? (
            <Text ta="center" c="embla.4" fw={400} size="lg">
              {t('weAppreciateYourTrustInEmbla')}
            </Text>
          ) : null}
        </Stack>
      ) : null}
      <Stack gap="sm">
        {showDownloadButton && <DownloadButton />}
        {showAppointmentInfo && (
          <AddToCalendarButton
            iconPosition="right"
            variant={isEmbeddedInApp ? 'primary' : 'subtle'}
            isEmbeddedInApp={isEmbeddedInApp}
            serverRoot={serverRoot}
            appointmentId={appointmentId}
          />
        )}
        {isEmbeddedInApp && (
          <GrowingButton
            variant="subtle"
            buttonLabel={t('finish')}
            onClick={() => {
              // on this url the app knows that it's time to close webview and refetch user data
              window.location.href = `${getRootPath()}/app-complete`;
            }}
            leftSection={<IconCheck width={20} />}
          />
        )}
        <PhoneImage locale={locale} type={getImageType(flowVariant)} />
      </Stack>
    </Stack>
  );
};

const getImageType = (flowVariant: IFunnelContext['flowVariant'] = 'booking') => {
  switch (flowVariant) {
    case 'watch-list-for-higher-dosage':
    case 'watch-list-screening-capacity':
      return 'healthlog';
    default:
      return 'consultation';
  }
};

const useConfirmationContent = (flowVariant: IFunnelContext['flowVariant'] = 'booking') => {
  let topText;
  let title;
  let lgText;

  switch (flowVariant) {
    case 'phone-already-registered':
      topText = t('alreadyRegistered');
      title = t('pleaseLoginInTheApp');
      break;
    case 'watch-list-for-higher-dosage':
      topText = t('thankYouForYourInterest');
      title = t('youWillBeNotifiedWhenHigerDosesAreAvailable');
      lgText = t('inTheMeantimeGetToKnowEmblaApp');
      break;
    case 'watch-list-screening-capacity':
      topText = t('thankYouForYourInterest');
      title = t('notifyMe.waitingForScreeningCapacity.title');
      lgText = t('notifyMe.description');
      break;
    default:
      topText = t('bookingConfirmed');
      title = t('thankYouForBooking');
      break;
  }

  return {
    topText,
    title,
    lgText,
  };
};

const ConfirmationStepper: FunctionComponent<{ email: string; isEmbeddedInApp: boolean }> = ({
  email,
  isEmbeddedInApp,
}) => {
  const renderStep = (icon: React.ReactNode, text: React.ReactNode) => {
    return (
      <Stepper.Step
        styles={{
          stepIcon: {
            backgroundColor: 'transparent',
            borderWidth: 0,
          },

          verticalSeparator: {
            marginTop: -8,
            marginBottom: -8,
            borderColor: colors.menthol[4],
            borderWidth: 1,
          },
        }}
        icon={icon}
        description={text}
      />
    );
  };
  return (
    <Stepper
      orientation="vertical"
      active={0}
      color={colors.menthol[4]}
      styles={{
        step: {
          paddingBottom: 8,
          marginBottom: -8,
        },
      }}
    >
      {renderStep(
        <IconEmail width={20} color={colors.menthol[4]} />,
        <Text c="embla.2" fw={600} size="lg">
          {t('weSentAConfirmationEmailTo', { email })}
        </Text>,
      )}
      {renderStep(
        <IconCamera width={20} color={colors.menthol[4]} />,
        <Text c="embla.4" fw={600} size="lg">
          {t('reminderNotification24HoursBeforeConsultation')}
        </Text>,
      )}
      {isEmbeddedInApp
        ? renderStep(
            <IconPhone width={20} color={colors.menthol[4]} />,
            <Text c="embla.4" fw={600} size="lg">
              {t('weLookForwardToMeetingYou')}
            </Text>,
          )
        : null}
    </Stepper>
  );
};
