import React, { FunctionComponent } from "react";
import { Quote } from "../types";
import { Text } from "@mantine/core";
import { EaseInAndOut } from "./EaseInAndOut";
import { TrustBox } from "./TrustBox";

interface IQuoteComponentProps extends Quote {
  isMounted: boolean;
  totalTime: number;
}

export const QuoteComponent: FunctionComponent<IQuoteComponentProps> = ({
  quote,
  reference,
  trustPilot,
  isMounted,
  totalTime,
}) => {
  return (
    <EaseInAndOut
      isMounted={isMounted}
      enterDuration={600}
      exitDuration={200}
      totalTime={totalTime}
    >
      <Text ta="center" c="embla.4" fw={600} style={{ fontSize: 24 }} py={"md"}>
        "{quote}"
      </Text>
      {trustPilot && <TrustBox />}
      {reference && (
        <Text ta="center" c="embla.3" fw={300} size={"sm"} fs="italic">
          {reference}
        </Text>
      )}
    </EaseInAndOut>
  );
};
