export const env = (function () {
  const root = `${import.meta.env['VITE_SERVER_ROOT_URL']}`;
  const gtmId = `${import.meta.env['VITE_GTM_ID']}`;
  const disableLoading = `${import.meta.env['VITE_DISABLE_LOAD']}` === 'true';

  return {
    serverRoot: root,
    disableLoading,
    gqlUrl: `${root}/graphql`,
    gtmId: gtmId,
    imageAssets: `${root}/assets/profile-images`,
    employeeImageAssets: `${root}/employee-assets/images`,
    growthbook: {
      clientKey: `${import.meta.env['VITE_GROWTHBOOK_CLIENT_KEY']}`,
      apiHost: `${import.meta.env['VITE_GROWTHBOOK_API_HOST']}`,
    },
    auth0: {
      clientID: `${import.meta.env['VITE_AUTH0_CLIENT_ID']}`,
      domain: `${import.meta.env['VITE_AUTH0_DOMAIN']}`,
    },
  };
})();
