import { Image, Stack, Text } from '@mantine/core';
import { QuoteComponent } from '@shared/components/QuoteComponent';
import { IFunnelLoaderConfig } from '@shared/funnel-engine';
import { EmployeeData } from '@shared/types';
import { FunctionComponent } from 'react';

import { getEmployeeByType, getLoadingTimeMsByType } from './employeeData';
import { ProductSelection } from '../FunnelContext';
import { useIndexStepper } from '../hooks/useIndexStepper';

const EmployeeWithTitleAndQuote: FunctionComponent<{
  getEmployee: () => EmployeeData;
}> = ({ getEmployee }) => {
  const { quotes, imageSrc, name, title } = getEmployee();
  const { currentIndex, currentStepTime: stepTime } = useIndexStepper(quotes.map((q) => q.time));

  return (
    <Stack align="center" mih={350} gap={0}>
      <Stack h={100}>
        <Image src={imageSrc} h={100} w="100px" radius={'md'} />
      </Stack>
      <Text ta="center" c="embla.3" fw={700} size={'lg'}>
        {name}
      </Text>
      <Text ta="center" c="embla.3" fw={400} size={'md'}>
        {title}
      </Text>
      {quotes.map((q, i) => (
        <QuoteComponent key={`qoute+${i}`} {...q} isMounted={currentIndex === i} totalTime={stepTime} />
      ))}
    </Stack>
  );
};

export const employeeLoaderCMO: IFunnelLoaderConfig = {
  component: EmployeeWithTitleAndQuote.bind(null, {
    getEmployee: () => getEmployeeByType('CMO'),
  }),
  minimumDurationSeconds: getLoadingTimeMsByType('CMO') / 1000,
};

export const employeeLoaderHeadOfCoaching: IFunnelLoaderConfig = {
  component: EmployeeWithTitleAndQuote.bind(null, {
    getEmployee: () => getEmployeeByType('HEAD_OF_COACHING'),
  }),
  minimumDurationSeconds: getLoadingTimeMsByType('HEAD_OF_COACHING') / 1000,
};

export const employeeLoaderMedical: IFunnelLoaderConfig = {
  component: EmployeeWithTitleAndQuote.bind(null, {
    getEmployee: () => getEmployeeByType('MEDICAL'),
  }),
  minimumDurationSeconds: getLoadingTimeMsByType('MEDICAL') / 1000,
};

export const employeeLoaderMedicalV2: IFunnelLoaderConfig = {
  component: EmployeeWithTitleAndQuote.bind(null, {
    getEmployee: () => getEmployeeByType('MEDICAL', false),
  }),
  minimumDurationSeconds: getLoadingTimeMsByType('MEDICAL', false) / 1000,
};

export const employeeLoaderCoach: IFunnelLoaderConfig = {
  component: EmployeeWithTitleAndQuote.bind(null, {
    getEmployee: () => getEmployeeByType('COACH'),
  }),
  minimumDurationSeconds: getLoadingTimeMsByType('COACH') / 1000,
};

export const employeeLoaderNurse: IFunnelLoaderConfig = {
  component: EmployeeWithTitleAndQuote.bind(null, {
    getEmployee: () => getEmployeeByType('NURSE'),
  }),
  minimumDurationSeconds: getLoadingTimeMsByType('NURSE') / 1000,
};

export const employeeLoaderNurse1: IFunnelLoaderConfig = {
  component: EmployeeWithTitleAndQuote.bind(null, {
    getEmployee: () => getEmployeeByType('NURSE_1'),
  }),
  minimumDurationSeconds: getLoadingTimeMsByType('NURSE_1') / 1000,
};

export const employeeLoaderNurse2: IFunnelLoaderConfig = {
  component: EmployeeWithTitleAndQuote.bind(null, {
    getEmployee: () => getEmployeeByType('NURSE_2'),
  }),
  minimumDurationSeconds: getLoadingTimeMsByType('NURSE_2') / 1000,
};

export const resolveYouQualifyForSelectedProductLoader = (selectedProduct: ProductSelection): IFunnelLoaderConfig => {
  const employeeType = selectedProduct === ProductSelection.MedicationAndCoaching ? 'NURSE_3' : 'NURSE_4';

  return {
    component: EmployeeWithTitleAndQuote.bind(null, {
      getEmployee: () => getEmployeeByType(employeeType),
    }),
    minimumDurationSeconds: getLoadingTimeMsByType(employeeType) / 1000,
  };
};
