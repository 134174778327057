import { TextProps, Group, Box, Text } from "@mantine/core";
import { FunctionComponent } from "react";
import { CustomColors } from "../theme";

interface IProps extends TextProps {
  indicatorColor: CustomColors;
  label: string;
}
export const IndicatorWithLabel: FunctionComponent<IProps> = ({
  indicatorColor,
  label,
  ...textProps
}) => {
  return (
    <Group wrap="nowrap" gap="sm" align="baseline">
      <Box w={10} h={10} bg={indicatorColor} style={{ borderRadius: 20 }}></Box>
      <Text c={indicatorColor} p={0} m={0} {...textProps}>
        {label}
      </Text>
    </Group>
  );
};
