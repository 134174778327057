import MounjaroLogo from '@assets/images/mounjaro-logo.png';
import WegovyLogo from '@assets/images/wegovy-logo.png';
import { Card, Divider, Group, Image, Pill, Stack, Text } from '@mantine/core';
import { colors } from '@shared/theme';
import { IconInfoCircle } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Medication: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Stack gap={0}>
      <Text py="xs" c="embla.3" fw="bold">
        {t('medication')}
      </Text>
      <Card>
        <Group align="flex-end" gap="xl">
          <Image h={64} src={MounjaroLogo} />
          <Image h={46} src={WegovyLogo} />
        </Group>
        <Divider my="md" color="embla.1" />
        <div>
          <Pill mb="sm" size="md" bg="embla.0">
            <Group gap="xs" h={25}>
              <IconInfoCircle size={12} color={colors.embla[4]} />
              <Text fw={600} c="embla.4" size="sm">
                {t('fixedPriceRegardlessOfDosage')}
              </Text>
            </Group>
          </Pill>
        </div>
        <Text c="embla.4">{t('aClinicianWillConfirmYourMedication')}</Text>
      </Card>
    </Stack>
  );
};
