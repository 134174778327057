import { List, Paper, Text } from "@mantine/core";
import { FunctionComponent, ReactNode } from "react";

type InfoRow = { icon?: ReactNode; value: string };
export const ListInfoCard: FunctionComponent<{
  config: Array<InfoRow>;
}> = ({ config }) => {
  return (
    <Paper radius={"lg"} shadow="md" w={"100%"} p={"md"} bg="menthol.0">
      <List spacing={"md"}>
        {config?.map((c) => {
          return (
            <List.Item icon={c.icon}>
              <Text lh={"24px"} fw={600} c={"embla.4"} size="md">
                {c.value}
              </Text>
            </List.Item>
          );
        })}
      </List>
    </Paper>
  );
};
