import { Card, RadioProps, Text, Radio } from "@mantine/core";
import { FunctionComponent } from "react";

import { EmblaRadio } from "./EmblaRadio";

export const RadioCard: FunctionComponent<
  RadioProps & { select: () => void }
> = ({ select, ...props }) => {
  return (
    <Card
      shadow="xs"
      bg={props.checked ? "menthol.0" : "neutral.0"}
      onClick={() => {
        select();
      }}
    >
      <EmblaRadio
        lh={24}
        {...props}
        label={
          <Text
            c="neutral.6"
            fw={600}
            onClick={(event) => event.stopPropagation()}
          >
            {props.label}
          </Text>
        }
      />
    </Card>
  );
};
