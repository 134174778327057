import { Stack, Text } from "@mantine/core";
import { BackButton } from "@shared/components/buttons/BackButton";
import { DownloadButton } from "@shared/components/buttons/DownloadButton";
import { PhoneImage } from "@shared/components/PhoneImage";
import { StepTitle } from "@shared/components/StepTitle";
import { FunnelPageComponent } from "@shared/funnel-engine";

import { t } from "../../i18n";
import IFunnelContext from "../FunnelContext";

export const NotEligible: FunnelPageComponent<
  Record<string, never>,
  IFunnelContext
> = ({ context, funnelApi }) => {
  const { embedded, locale, notEligibleReason, measurements } = context;
  const { bmi } = measurements;
  const { title, mdText1, mdText2 } = useNotEligibleContent(
    notEligibleReason,
    bmi,
  );
  const showDownloadButton =
    notEligibleReason === "low-bmi" || notEligibleReason === "addiction";

  return (
    <Stack gap="lg" py="md">
      <StepTitle size="lg" mt={0} mb={0} title={title} />
      <Stack gap="lg">
        {mdText1 && (
          <Text ta="center" c="embla.3">
            {mdText1}
          </Text>
        )}
        {mdText2 && (
          <Text ta="center" c="embla.3">
            {mdText2}
          </Text>
        )}
      </Stack>
      {showDownloadButton && !embedded ? (
        <Stack gap="md">
          <Text ta="center" c="embla.5" size="lg" fw={600}>
            {t("youAreWelcomeToDownload")}
          </Text>
          <DownloadButton />
          <BackButton onClick={() => funnelApi.back()} />
          <PhoneImage locale={locale} type={"healthlog"} />
        </Stack>
      ) : (
        <Stack>
          {showDownloadButton && embedded && (
            <Text ta="center" c="embla.5" size="lg" fw={600}>
              {t("youAreWelcomeToUse")}
            </Text>
          )}
          <BackButton onClick={() => funnelApi.back()} />
        </Stack>
      )}
    </Stack>
  );
};

const useNotEligibleContent = (
  reason: IFunnelContext["notEligibleReason"],
  bmi: number,
) => {
  const title = t("thanksForYourAnswers");
  let mdText1;
  let mdText2;

  switch (reason) {
    case "low-bmi":
    case "very-low-bmi":
      mdText1 = t("notEligibleBmi1", { bmi });
      mdText2 = t("notEligibleBmi2");
      break;
    case "eating-disorder":
      mdText1 = t("notEligibleEatingDisorder");
      break;
    case "addiction":
      mdText1 = t("notEligibleDrugsAlcohol");
      break;
  }

  return {
    title,
    mdText1,
    mdText2,
  };
};
