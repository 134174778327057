import { Loader, Stack, Text } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { Address } from '@shared/gql/sdk';
import { showErrorNotification } from '@shared/showNotification';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import IFunnelContext from 'src/v3/FunnelContext';

import SubscriptionFlow from './Subscribe';
import { YourRecommendedMembership } from './YourRecommendedMembership';

export const Pay: FunnelPageComponent<{ address: Address }, IFunnelContext> = ({
  data: { address },
  context: { selectedProduct, selectedProductPriceInfo: price, stripeCredentials },
  funnelApi,
}) => {
  const [confirmErrorMessage, setConfirmErrorMessage] = useState<string>();
  const { t } = useTranslation();

  if (!price) return <Loader />;

  return (
    <Stack>
      <StepTitle title={<Trans i18nKey={'justOneStepLeft'} />} />
      <Stack gap="lg">
        <YourRecommendedMembership price={price} selectedProduct={selectedProduct} selected compact />

        <Stack gap={'xs'}>
          <Text c="embla.3" fw="bold">
            {t('payWith')}
          </Text>
          {confirmErrorMessage && <Text c="error.2">{confirmErrorMessage}</Text>}
          <SubscriptionFlow
            {...stripeCredentials}
            onSuccess={() =>
              funnelApi.next({
                address,
              })
            }
            onError={(errorMessage) => {
              setConfirmErrorMessage(errorMessage);
              showErrorNotification(errorMessage);
            }}
            requiresConfirmation={false}
            submitLabel={t('pay')}
          />
          <BackButton onClick={() => funnelApi.back()} />
        </Stack>
      </Stack>
    </Stack>
  );
};
