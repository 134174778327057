import CountryDK from "@assets/images/countries/DK.png";
import CountryGB from "@assets/images/countries/GB.png";
import { Avatar, Select } from "@mantine/core";
import { LocaleIso6391 } from "@shared/gql/sdk";
import { resolveIntl } from "@shared/helpers";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { changeLocale } from "src/resolveIntl";

export const LanguageSelect: FunctionComponent = () => {
  const { t } = useTranslation();
  const { locale } = resolveIntl();
  const [selectedLocale, setSelectedLocale] = useState<LocaleIso6391>(locale);

  useEffect(() => {
    changeLocale(selectedLocale);
  }, [selectedLocale]);

  const languages = [
    {
      image: CountryDK,
      label: t("iSpeakDanish"),
      value: LocaleIso6391.Da,
    },
    {
      image: CountryGB,
      label: t("iSpeakEnglish"),
      value: LocaleIso6391.En,
    },
  ];

  return (
    <Select
      mb="xs"
      size="lg"
      defaultValue={locale}
      data={languages}
      onChange={(locale) => {
        setSelectedLocale(locale as LocaleIso6391);
      }}
      withCheckIcon={false}
      allowDeselect={false}
      leftSection={
        <Avatar
          key={locale}
          size="sm"
          src={languages.find((l) => l.value === selectedLocale).image}
        />
      }
    />
  );
};
