import { ReactComponent as CheckIcon } from "@assets/icons/fi-rr-check.svg";
import { Checkbox, CheckboxProps } from "@mantine/core";
import { FunctionComponent } from "react";

export const EmblaCheckbox: FunctionComponent<CheckboxProps> = (props) => {
  return (
    <Checkbox
      {...props}
      icon={CheckIcon}
      styles={{
        root: {
          marginLeft: 3,
        },
        input: {
          borderColor: "var(--mantine-color-neutral-3)",
        },
        icon: {
          height: 16,
          width: 16,
          top: 4,
          left: 4,
          margin: "unset",
          transition: "transform .1s ease,opacity .1s ease",
        },
        error: {
          color: "var(--mantine-color-error-3)",
          fontSize: "var(--mantine-color-font-size-sm)",
        },
        label: {
          color: "var(--mantine-color-embla-4)",
          fontSize: "var(--mantine-color-font-size-md)",
          fontWeight: 400,
          paddingLeft: 12,
        },
        body: {
          alignItems: "center",
        },
      }}
    />
  );
};
