import api from "@shared/api";
import {
  CountryIso3166,
  EligibilityType,
  FinancialPrice,
  LocaleIso6391,
  UserGetQuery,
} from "@shared/gql/sdk";

import { t } from "../../i18n";
import IFunnelContext, { ProductSelection } from "../FunnelContext";

export const anyTicked = (form: { [key: string]: boolean }) => {
  return Object.keys(form).some((key) => form[key as keyof typeof form]);
};

export const onlyTicked = <T extends { [key: string]: boolean }>(
  form: T,
  key: keyof T,
) => {
  return Object.keys(form).every((k) => {
    if (k === key) {
      return form[k] === true;
    } else return form[k] === false;
  });
};

export const onlyTickedOneOrMore = <T extends { [key: string]: boolean }>(
  form: T,
  keys: (keyof T)[],
) => {
  return Object.keys(form).every((k) => {
    if (form[k] === true) {
      return keys.includes(k);
    }
    return true;
  });
};

export const getSessionLock = (lockName: string) => {
  if (sessionStorage.getItem(lockName)) return false;

  sessionStorage.setItem(lockName, "true");
  return true;
};

export const initializeFromURL = (): Partial<IFunnelContext> => {
  const params = new URLSearchParams(window.location.search);
  const promoCode = params.get("promoCode");
  const embedded = params.get("app") === "true";
  return {
    prefilledDiscountCode: promoCode,
    embedded,
  };
};

const getDiscountCode = (user: UserGetQuery["User"]) => {
  if (!user) return;

  try {
    const parsedMetaData = JSON.parse(user.metaData);
    return parsedMetaData.emblaShareSignupValue;
  } catch (e) {
    return;
  }
};

export const initializeSession = async (
  token: string,
): Promise<{
  context: Partial<IFunnelContext>;
  user: UserGetQuery["User"];
}> => {
  sessionStorage.setItem("token", token);
  const { User } = await api.UserGet();
  const { phoneNumber, phoneVerified, id } = User;

  return {
    context: {
      prefilledDiscountCode: getDiscountCode(User),
      userSession: {
        firstName: User.firstName,
        phoneConfirmed: phoneVerified,
        phoneNumber: phoneNumber,
        token,
        userId: id,
        email: User.email,
      },
    },
    user: User,
  };
};

export const getPriceInfoByProductSelection = (
  productSelection: ProductSelection,
  priceInfos: FinancialPrice[],
) => {
  switch (productSelection) {
    case ProductSelection.CoachingOnly:
      return priceInfos.find(
        (pi) => pi.product.eligibilityType === EligibilityType.Coaching,
      );
    case ProductSelection.MedicationAndCoaching:
    case ProductSelection.CoachingAndMaybeMedication:
      return priceInfos.find(
        (pi) =>
          pi.product.eligibilityType === EligibilityType.CoachingAndMedication,
      );
  }
};

const getDefaultProductTitle = (product: ProductSelection) => {
  switch (product) {
    case ProductSelection.MedicationAndCoaching:
    case ProductSelection.CoachingAndMaybeMedication:
      return t("medicalWeightLossProgram.title");
    case ProductSelection.CoachingOnly:
      return t("coachingOnly.title");
    default:
      return product.toString();
  }
};

export const getDefaultPriceInfo = (
  selectedProduct: ProductSelection,
  country: CountryIso3166,
) => {
  const defaultName = getDefaultProductTitle(selectedProduct);
  // FALLBACK PRICE INFO - STATIC - SHOULD IDEALLY NEVER GET CALLED
  const currency = country === CountryIso3166.Gb ? "gbp" : "dkk";
  let amount: number;

  switch (selectedProduct) {
    case ProductSelection.MedicationAndCoaching:
    case ProductSelection.CoachingAndMaybeMedication:
      amount = country === CountryIso3166.Gb ? 29900 : 99500;
      break;
    case ProductSelection.CoachingOnly:
    case ProductSelection.MedicationTaperingOff:
      amount = country === CountryIso3166.Gb ? 9900 : 49500;
      break;
  }

  return {
    name: defaultName,
    currency,
    amount,
    campaignText: undefined as string,
    campaignAmount: undefined as number,
    campaignDiscount: undefined as {
      duration: string;
      durationInMonths: number;
    },
  };
};

export const formatPrice = (
  amount: number,
  locale: LocaleIso6391,
  currency: string,
) => {
  const formatCurrency = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  });

  return formatCurrency.format(amount / 100);
};
