import { FunnelRoutes } from "@shared/funnel-engine";
import { TrackingEventName } from "@shared/gql/sdk";
import { trackingService } from "@shared/TrackingContext";

import IFunnelContext from "../../../FunnelContext";
import { getSessionLock } from "../../../util/helpers";
import { createNotifyMeFunnelConfig } from "../config";

type Pages = FunnelRoutes<
  ReturnType<typeof createNotifyMeFunnelConfig>["config"]
>;

const { trackEvent, pushGTMEvent } = trackingService;

export const onPageEntry = (page: Pages) => {
  switch (page) {
    case "notify_me/registration":
      if (getSessionLock("signupStarted")) {
        trackEvent(TrackingEventName.SignupStarted);
      }
      break;
    case "notify_me/confirm_phone":
      trackEvent(TrackingEventName.SignupBookingcodeAsked);
      break;
  }
};

export const onPageExit = (page: Pages, context: IFunnelContext) => {
  switch (page) {
    case "notify_me/registration":
      if (context.userSession?.userId) {
        trackEvent(TrackingEventName.SignupRegistered);
        pushGTMEvent("user_registered", {
          userId: context.userSession.userId,
        });
      }
      break;
    case "notify_me/confirm_phone":
      if (context.flowVariant === "booking") {
        trackEvent(TrackingEventName.SignupWatchlistConfirmed);
      }
      break;
  }
};
