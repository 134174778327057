import EmblaCoach from '@assets/images/embla-coach.png';
import EmblaTeam from '@assets/images/embla-team.png';
import { Card, Checkbox, Divider, Group, Image, Stack, Text, UnstyledButton } from '@mantine/core';
import { FinancialPrice } from '@shared/gql/sdk';
import { formatAmount, getProductNameByProduct } from '@shared/helpers';
import { colors } from '@shared/theme';
import {
  IconClipboardPlus,
  IconDeviceMobile,
  IconMedicineSyrup,
  IconRoute,
  IconTruckDelivery,
  IconUsersGroup,
} from '@tabler/icons-react';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductSelection } from 'src/v3/FunnelContext';

interface Props {
  selectedProduct: ProductSelection;
  price: FinancialPrice;
  selected: boolean;
  onClick?: () => void;
  compact?: boolean;
}

interface BulletItem {
  icon: ReactNode;
  label: string;
}

const YourRecommendedMembership: React.FC<Props> = ({ selectedProduct, price, selected, onClick, compact }) => {
  const { t } = useTranslation();
  const isEmblaTeam = selectedProduct === ProductSelection.MedicationAndCoaching;

  return (
    <UnstyledButton onClick={onClick}>
      <Stack gap={0}>
        <Text py="xs" c="embla.3" fw="bold">
          {t('yourRecommendedMembership')}
        </Text>

        <Card>
          <Top isEmblaTeam={isEmblaTeam} price={price} selected={selected} />
          {!compact && (
            <>
              <Divider my="md" color="embla.1" />
              <BulletPoints items={getItems(isEmblaTeam, t)} />
            </>
          )}
        </Card>
      </Stack>
    </UnstyledButton>
  );
};

export { YourRecommendedMembership };

const Top: React.FC<{ isEmblaTeam: boolean; price: FinancialPrice; selected: boolean }> = ({
  isEmblaTeam,
  price,
  selected,
}) => {
  const { t } = useTranslation();

  return (
    <Group align="flex-start" wrap="nowrap">
      <Card
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        px={12}
        bg="menthol.1"
        w={56}
        h={80}
      >
        <Image src={isEmblaTeam ? EmblaTeam : EmblaCoach} />
      </Card>
      <Stack style={{ flexGrow: 1 }} gap={0}>
        <Group justify="space-between" wrap="nowrap" align="top">
          <Text fw={400} lh={'30px'} size={'20px'} c="embla.4">
            {getProductNameByProduct(price.product)}
          </Text>
          <Checkbox checked={selected} readOnly />
        </Group>
        <Text fw={600} c="embla.4">
          {isEmblaTeam ? t('medicationSupportedPlan') : t('comprehensiveCoachingPlan')}
        </Text>
        {price?.campaignAmount ? (
          <Group>
            <Text c="embla.4" td="line-through">
              {formatAmount(price.amount, price.currency)}/{t('month')}
            </Text>
            <Text c="embla.4">
              {formatAmount(price.campaignAmount, price.currency)}
              {price.campaignText ? `/${price.campaignText}` : `/{t('month')`}
            </Text>
          </Group>
        ) : (
          <Text c="embla.4">
            {formatAmount(price.amount, price.currency)}/{t('month')}
          </Text>
        )}
      </Stack>
    </Group>
  );
};

const BulletPoints: React.FC<{
  items: BulletItem[];
}> = ({ items = [] }) => {
  const { t } = useTranslation();

  return (
    <Stack gap="sm">
      {items.map(({ icon, label }) => (
        <Group style={{ flexWrap: 'initial' }} key={label} gap="sm" align="center">
          <Card
            style={{
              padding: 8,
              borderRadius: 50,
            }}
            bg="menthol.0"
          >
            {icon}
          </Card>
          <Text c="embla.4" fw={600}>
            {t(label)}
          </Text>
        </Group>
      ))}
    </Stack>
  );
};

const getItems = (isEmblaTeam: boolean, t: (key: string) => string): BulletItem[] => {
  return isEmblaTeam
    ? [
        {
          label: t('medicationAtFlatRate'),
          icon: <IconMedicineSyrup color={colors.menthol[4]} size={20} />,
        },
        {
          label: t('coachPsychologistAndDoctor'),
          icon: <IconUsersGroup color={colors.menthol[4]} size={20} />,
        },
        {
          label: t('1to1HealthGuidance'),
          icon: <IconRoute color={colors.menthol[4]} size={20} />,
        },
        {
          label: t('fullAccessToEmblaApp'),
          icon: <IconDeviceMobile color={colors.menthol[4]} size={20} />,
        },
        {
          label: t('monthlyFreeMedicationShipments'),
          icon: <IconTruckDelivery color={colors.menthol[4]} size={20} />,
        },
      ]
    : [
        {
          label: t('dedicatedHealthCoach'),
          icon: <IconUsersGroup color={colors.menthol[4]} size={20} />,
        },
        {
          label: t('1to1NutrationAndWellBeingGuidance'),
          icon: <IconRoute color={colors.menthol[4]} size={20} />,
        },
        {
          label: t('personalisedActionPlanForTrackingProgress'),
          icon: <IconClipboardPlus color={colors.menthol[4]} size={20} />,
        },
        {
          label: t('fullAccessToEmblaApp'),
          icon: <IconDeviceMobile color={colors.menthol[4]} size={20} />,
        },
      ];
};
