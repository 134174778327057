import { Anchor, Stack, Text } from '@mantine/core';
import { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';
import { ProductSelection } from 'src/v3/FunnelContext';

interface IProps {
  selectedProduct: ProductSelection;
  productChanged: boolean;
  onClick: () => void;
}

export const SwitchProgram: FunctionComponent<IProps> = ({ selectedProduct, productChanged, onClick }) => {
  // t("notInterestedInCoachingOnlySeeCoachingAndMedicationProgram")
  // t("notInterestedInMedicationSeeEmblaCoachProgram")
  if (selectedProduct === ProductSelection.CoachingOnly && !productChanged) {
    return null;
  }
  return (
    <Stack>
      <Text ta={'center'} size={'sm'} c={'menthol.4'}>
        <Trans
          i18nKey={
            selectedProduct === ProductSelection.CoachingOnly
              ? 'notInterestedInCoachingOnlySeeCoachingAndMedicationProgram'
              : 'notInterestedInMedicationSeeEmblaCoachProgram'
          }
          components={{
            line: <span style={{ display: 'block' }} />,
            a: (
              <Anchor
                fw={700}
                size="sm"
                c={'menthol.4'}
                style={{ display: 'inline', textAlign: 'center', textDecoration: 'underline' }}
                onClick={onClick}
              ></Anchor>
            ),
          }}
        />
      </Text>
    </Stack>
  );
};
