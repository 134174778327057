import api from "@shared/api";
import { funnelConfiguration, funnelPage } from "@shared/funnel-engine";
import { MarketingLeadTag } from "@shared/gql/sdk";

import { registrationExitHandler } from "./handlers/registrations";
import { onPageEntry, onPageExit } from "./handlers/tracking";
import {
  employeeLoaderCMO,
  employeeLoaderMedical,
} from "../../loaders/employeeLoaders";
import { Confirmation } from "../../pages/Confirmation";
import { ConfirmPhoneNumber } from "../../pages/ConfirmPhoneNumber";
import { Registration, RegistrationErrors } from "../../pages/Registration";
import {
  performPasswordLessLogin,
  startPasswordlessLogin,
} from "../../util/passwordLessLogin";

export const createNotifyMeFunnelConfig = () =>
  funnelConfiguration({
    handlers: {
      onPageEntry: ({ page }) => {
        onPageEntry(page);
      },
      onPageExit: ({ page, context }) => {
        onPageExit(page, context);
      },
    },
    config: {
      notify_me: {
        registration: funnelPage(Registration, {
          onExit: registrationExitHandler,
          loader: employeeLoaderCMO,
        }),
        confirm_phone: funnelPage(ConfirmPhoneNumber, {
          onEntry: async ({
            context: {
              userSession: { phoneNumber },
              flowVariant,
            },
          }) => {
            if (flowVariant === "phone-already-registered") {
              await startPasswordlessLogin(phoneNumber);
            } else {
              await api.UserStartIdConfirmationSMS({ phoneNumber });
            }
          },
          actions: {
            sendSMSCode: async (phoneNumber, { context }) => {
              const { flowVariant } = context;
              if (flowVariant === "phone-already-registered") {
                await startPasswordlessLogin(phoneNumber);
              } else {
                await api.UserStartIdConfirmationSMS({ phoneNumber });
              }
            },
            confirmPhoneNumber: async (
              phoneNumber,
              code,
              { funnelApi, context },
            ) => {
              const { flowVariant } = context;

              if (flowVariant === "phone-already-registered") {
                const result = await performPasswordLessLogin(
                  phoneNumber,
                  code,
                );
                if (result) {
                  funnelApi.updateContext({
                    userSession: {
                      phoneNumber,
                      token: result.token,
                      userId: result.userId,
                      phoneConfirmed: true,
                    },
                    flowVariant: "watch-list-screening-capacity",
                  });
                  return true;
                }
              } else {
                const result = await api.UserCompleteIdConfirmationSMS({
                  phoneNumber,
                  code,
                });
                return result.UserCompleteIdConfirmation.success;
              }
            },
          },
        }),
        confirmation: funnelPage(Confirmation, {
          onEntry: async ({ context }) => {
            const marketingLead = await api.MarketingLeadByClientId({
              clientId: context.userSession.userId,
            });

            await api.AddTagsToMarketingLead({
              id: marketingLead.MarketingLeadByClientId.id,
              tags: [MarketingLeadTag.WaitingForScreeningCapacity],
            });
          },
          terminal: true,
        }),
      },
    },
    routingRules: {
      notify_me: {
        registration: ({ error }) => {
          if (error === RegistrationErrors.PhoneOrEmailAlreadyInUse) {
            return "notify_me/registration";
          }
        },
      },
    },
    initialContext: {
      flowVariant: "watch-list-screening-capacity",
    },
    initialLoader: employeeLoaderMedical,
    defaultLoader: {
      component: () => null,
      minimumDurationSeconds: 1,
      progressBar: true,
    },
  });
