import { ReactComponent as IconClock } from "@assets/icons/fi-rr-clock.svg";
import {
  Group,
  Paper,
  SimpleGrid,
  Stack,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { colors } from "../theme";

interface IProps {
  options: string[];
  selected?: string;
  setTime: (time: string) => void;
  durationInMinutes: number;
}

export const TimeSelector: FunctionComponent<IProps> = ({
  options,
  selected,
  setTime,
  durationInMinutes,
}) => {
  const { t } = useTranslation();
  const { width } = useViewportSize();
  return (
    <Stack gap={"sm"}>
      <Group justify="space-between">
        <Text c={"embla.4"} fw={600} size={"lg"}>
          {t("selectTime")}
        </Text>
        {durationInMinutes ? (
          <Group align="center" gap="xs">
            <IconClock height={16} color={colors.embla[3]} />
            <Text c={"embla.3"} fw={600} size={"md"}>
              {`${durationInMinutes}min`}
            </Text>
          </Group>
        ) : null}
      </Group>
      <SimpleGrid cols={width < 400 ? 3 : 4} spacing={8}>
        {options.map((opt) => {
          const isSelected = selected === opt;
          return (
            <UnstyledButton
              m="xs"
              key={opt}
              onClick={() => {
                setTime(opt);
              }}
              styles={{
                root: {
                  borderRadius: "8px",
                },
              }}
            >
              <Paper
                py={"xs"}
                px={"sm"}
                radius={"md"}
                role="button"
                shadow={isSelected ? "sm" : "xs"}
                bg={selected === opt ? "menthol.0" : "neutral.0"}
                style={{
                  border: isSelected
                    ? `solid 1px ${colors.menthol[2]}`
                    : undefined,
                }}
              >
                <Group justify="center">
                  <Text c={"embla.4"} fw={600}>
                    {opt}
                  </Text>
                </Group>
              </Paper>
            </UnstyledButton>
          );
        })}
      </SimpleGrid>
    </Stack>
  );
};
