import { FunctionComponent } from "react";
import { IFunnelSession } from "./v3/FunnelContext";
import { useUserSession } from "./v3/hooks/sessionHooks";

export const App: FunctionComponent<{
  funnelComponent: FunctionComponent<{
    session: IFunnelSession;
  }>;
}> = (props) => {
  const { initialized, session } = useUserSession();
  if (!initialized) return null;
  return <props.funnelComponent session={session} />;
};
