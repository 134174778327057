import bike from "@assets/embla-method/bike.svg";
import glp1 from "@assets/embla-method/glp1.svg";
import healthcareTeam from "@assets/embla-method/healthcare-team.svg";
import memberGroups from "@assets/embla-method/member-groups.svg";
import oneEasyApp from "@assets/embla-method/one-easy-app.svg";
import personalHealthMentor from "@assets/embla-method/personal-health-mentor.svg";
import pizza from "@assets/embla-method/pizza.svg";
import { Anchor, Group, Image, SimpleGrid, Stack, Text } from "@mantine/core";
import { BackButton } from "@shared/components/buttons/BackButton";
import { NextButton } from "@shared/components/buttons/NextButton";
import { DescriptionCard } from "@shared/components/DescriptionCard";
import { SimpleProductCard } from "@shared/components/SimpleProductCard";
import { StepTitle } from "@shared/components/StepTitle";
import { FunnelPageComponent } from "@shared/funnel-engine";
import { CountryIso3166 } from "@shared/gql/sdk";
import { Trans, useTranslation } from "react-i18next";
import { resolveIntl } from "src/resolveIntl";

import { t } from "../../../i18n";
import IFunnelContext, { ProductSelection } from "../../FunnelContext";
import {
  formatPrice,
  getDefaultPriceInfo,
  getPriceInfoByProductSelection,
} from "../../util/helpers";

// t("seeMedicationAndCoaching")
// t("seeMedicineFree")

export const EmblaMethodWithProductCard: FunnelPageComponent<
  {
    selectedProduct?: ProductSelection;
    productChanged?: boolean;
  },
  IFunnelContext
> = ({ funnelApi, context: { selectedProduct, priceInfos, eligibleFor } }) => {
  const { t } = useTranslation();
  const { locale, country } = resolveIntl();
  const coachingAndMedication =
    selectedProduct === ProductSelection.CoachingAndMaybeMedication ||
    selectedProduct === ProductSelection.MedicationAndCoaching;
  const cards = coachingAndMedication
    ? getCoachingAndMedicationCards()
    : getCoachingCards();

  const switchTo =
    selectedProduct === ProductSelection.CoachingOnly
      ? ProductSelection.MedicationAndCoaching
      : ProductSelection.CoachingOnly;

  const hasMedication = [
    ProductSelection.CoachingAndMaybeMedication,
    ProductSelection.MedicationAndCoaching,
  ].includes(selectedProduct);

  let pricePer = t("coachingOnly.pricePer");

  if (hasMedication) {
    if (country === CountryIso3166.Gb) {
      pricePer = t("medicalWeightLossProgram.pricePerUK");
    } else {
      pricePer = t("medicalWeightLossProgram.pricePerDK");
    }
  }

  const priceInfo =
    getPriceInfoByProductSelection(selectedProduct, priceInfos || []) ??
    getDefaultPriceInfo(selectedProduct, country);

  let actualPrice: string;
  let originalPrice: string = null;

  if (priceInfo.campaignAmount) {
    if (
      priceInfo.campaignDiscount?.duration === "once" ||
      priceInfo.campaignDiscount.durationInMonths === 1
    ) {
      pricePer = pricePer.replace(t("perMonth"), t("firstMonth"));
    }
    actualPrice = formatPrice(
      priceInfo.campaignAmount,
      locale,
      priceInfo.currency,
    );
    originalPrice = formatPrice(priceInfo.amount, locale, priceInfo.currency);
  } else {
    actualPrice = formatPrice(priceInfo.amount, locale, priceInfo.currency);
  }

  const hideFooter =
    selectedProduct === ProductSelection.CoachingOnly &&
    eligibleFor === ProductSelection.CoachingOnly;

  return (
    <Stack>
      <StepTitle title={t("theEmblaMethod")} />

      <SimpleGrid cols={2} spacing={12}>
        {cards.map((c) => {
          return (
            <DescriptionCard
              key={c.title}
              title={c.title}
              description={c.description}
              image={
                c.image ? <Image src={c.image} height={32} width={32} /> : null
              }
            />
          );
        })}
      </SimpleGrid>
      <SimpleProductCard
        discountText={priceInfo?.campaignText}
        name={priceInfo?.name}
        beforePrice={originalPrice}
        price={actualPrice}
        priceSuffix={pricePer}
        priceDetails={
          hasMedication && country === CountryIso3166.Dk
            ? t("excludingMedicationExpenses")
            : null
        }
        footer={
          !hideFooter && (
            <Stack gap={0} align="center" justify="center">
              <Text c={"embla.3"} size="sm" ta="center">
                {selectedProduct === ProductSelection.CoachingOnly
                  ? t("interestedInMedication")
                  : t("notInterestedInMedication")}
              </Text>
              <Group
                align="center"
                justify="center"
                gap="xs"
                style={{
                  color: "var(--mantine-color-embla-3)",
                  fontSize: "var(--mantine-font-size-sm)",
                }}
              >
                <Trans
                  i18nKey={
                    selectedProduct === ProductSelection.CoachingOnly
                      ? "seeMedicationAndCoaching"
                      : "seeMedicineFree"
                  }
                  components={{
                    a: (
                      <Anchor
                        fw={700}
                        size="sm"
                        c={"embla.3"}
                        style={{ display: "inline", textAlign: "center" }}
                        onClick={() => {
                          funnelApi.next({
                            selectedProduct: switchTo,
                            productChanged: true,
                          });
                        }}
                      ></Anchor>
                    ),
                  }}
                />
              </Group>
            </Stack>
          )
        }
      />
      <NextButton
        onClick={() =>
          funnelApi.next({
            selectedProduct,
            productChanged: false,
          })
        }
      />
      <BackButton onClick={() => funnelApi.back()} />
    </Stack>
  );
};

const getCoachingAndMedicationCards = () => {
  return [
    {
      image: pizza,
      title: t("noCalorieCounting"),
      description: t("eatWhatYouLove"),
    },
    {
      image: glp1,
      title: t("glp1Medication"),
      description: t("surpressYourAppetite"),
    },
    {
      image: healthcareTeam,
      title: t("healthcareTeam"),
      description: t("noLongWaitingTimes"),
    },
    {
      image: personalHealthMentor,
      title: t("personalHealthMentor"),
      description: t("certifiedInNutritionPhyscalAndMentalHealth"),
    },
    {
      image: memberGroups,
      title: t("memberGroups"),
      description: t("aJudgementFreeSupportNetwork"),
    },
    {
      image: oneEasyApp,
      title: t("oneEasyApp"),
      description: t("trackGoalsFindRecipes"),
    },
  ];
};

const getCoachingCards = () => {
  return [
    {
      image: pizza,
      title: t("noCalorieCounting"),
      description: t("eatWhatYouLove"),
    },
    {
      image: bike,
      title: t("smallSteps"),
      description: t("noIntenseWorkouts"),
    },
    {
      image: healthcareTeam,
      title: t("onlineClinic"),
      description: t("expertGuidanceFromHome"),
    },
    {
      image: personalHealthMentor,
      title: t("personalHealthMentor"),
      description: t("certifiedInNutritionPhyscalAndMentalHealth"),
    },
    {
      image: memberGroups,
      title: t("memberGroups"),
      description: t("aJudgementFreeSupportNetwork"),
    },
    {
      image: oneEasyApp,
      title: t("oneEasyApp"),
      description: t("trackGoalsFindRecipes"),
    },
  ];
};
